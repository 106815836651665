import { ACTION_SCAN_QR } from '@/utils/shareBoxHelper'

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Account/User/Login.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Account/User/Register.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/Account/User/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/staff/users',
    name: 'staff-users-management',
    component: () => import('@/views/Account/Staff/ListUsers.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_user',
    },
  },
  {
    path: '/staff/users/:id',
    component: () => import('@/views/Account/Staff/UserDetail.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_user',
    },
    children: [
      {
        path: '',
        name: 'staff-user-detail',
        component: () => import('@/views/Account/Staff/components/UserProfile.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'family',
        name: 'staff-user-family',
        component: () => import('@/views/Account/Staff/components/UserFamily.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'role',
        name: 'staff-user-role',
        component: () => import('@/views/Account/Staff/components/UserRole.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'update',
        name: 'staff-user-update',
        component: () => import('@/views/Account/Staff/components/UserProfileUpdate.vue'),
        meta: {
          layout: 'content',
        },
      },
    ],
  },
  {
    path: '/staff/users/:id/reset-password',
    name: 'staff-user-reset-password',
    component: () => import('@/views/Account/Staff/components/UserResetPassword.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_user',
    },
  },

  {
    path: '/user/update-information',
    name: 'user-update-information',
    component: () => import('@/views/Account/User/UserInformationUpdate.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },
  {
    path: '/user/family',
    name: 'user-family',
    component: () => import('@/views/Account/User/UserFamily.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },
  {
    path: '/user/family/add-member',
    name: 'user-family-add-member',
    component: () => import('@/views/Account/User/UserFamilyAddMember.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },
  {
    path: '/user/activity',
    name: 'user-activity',
    component: () => import('@/views/Account/User/UserActivity.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },

  {
    path: '/user/:id?',
    name: 'user-profile',
    component: () => import('@/views/Account/User/UserProfile.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },

  // Town Infirmary
  {
    path: '/staff/town-infirmary',
    name: 'staff-town-infirmary',
    component: () => import('@/views/TownInfirmary/Staff/ListWait.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_town',
    },
  },
  {
    path: '/staff/town-exam-list',
    name: 'staff-town-exam-list',
    component: () => import('@/views/TownInfirmary/Staff/ListExam.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_town',
    },
  },
  {
    path: '/staff/town-infirmary/checkup/:id',
    name: 'staff-town-infirmary-checkup',
    component: () => import('@/views/TownInfirmary/Staff/CheckUp.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_town',
    },
  },
  {
    path: '/staff/town-infirmary/checkup-edit/:id',
    name: 'staff-town-infirmary-checkup-edit',
    component: () => import('@/views/TownInfirmary/Staff/CheckUp.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_town',
    },
    props: { edit: true },
  },
  {
    path: '/staff/town-infirmary/checkin',
    name: 'staff-town-infirmary-checkin',
    component: () => import('@/views/TownInfirmary/Staff/CheckIn.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_town',
    },
  },
  {
    path: '/town-infirmary',
    name: 'town-infirmary-user',
    component: () => import('@/views/TownInfirmary/User/ListHealthInformation.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },
  {
    path: '/town-infirmary/:id',
    name: 'town-infirmary-user-view',
    component: () => import('@/views/TownInfirmary/User/HealthInformation.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },

  // Cafe
  {
    path: '/staff/cafe',
    name: 'staff-cafe',
    component: () => import('@/views/Cafe/Staff/CafeStaff.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_cafe',
    },
  },
  {
    path: '/staff/cafe-export',
    name: 'staff-cafe-export',
    component: () => import('@/views/Cafe/Staff/StaffExport.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_coupon',
    },
  },
  {
    path: '/staff/cafe/scan-coupon',
    name: 'staff-cafe-scan-coupon',
    component: () => import('@/views/Cafe/Staff/ScanCoupon.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_cafe',
    },
  },
  {
    path: '/stafff/cafe/activity',
    name: 'staff-cafe-activity',
    component: () => import('@/views/Cafe/Staff/StaffDetail.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_cafe',
    },
  },
  {
    path: '/cafe',
    name: 'cafe',
    component: () => import('@/views/Cafe/User/CouponList.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },

  // Sharebox
  {
    path: '/sharebox',
    component: () => import('@/views/ShareBox/User/UserShareBox.vue'),
    meta: {
      layout: 'content',
    },
    children: [
      {
        path: '',
        name: 'sharebox',
        component: () => import('@/views/ShareBox/User/Borrowed.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'can-borrow',
        name: 'sharebox-can-borrow',
        component: () => import('@/views/ShareBox/User/CanBorrow.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'lend',
        name: 'sharebox-lend',
        component: () => import('@/views/ShareBox/User/Lend.vue'),
        meta: {
          layout: 'content',
        },
      },
    ],
  },
  {
    path: '/sharebox/create',
    name: 'sharebox-create',
    component: () => import('@/views/ShareBox/components/ScanQR.vue'),
    meta: {
      action: ACTION_SCAN_QR.CREATE,
      layout: 'content',
    },
  },
  {
    path: '/sharebox/return',
    name: 'sharebox-return',
    component: () => import('@/views/ShareBox/components/ScanQR.vue'),
    meta: {
      action: ACTION_SCAN_QR.RETURN,
      layout: 'content',
    },
  },
  {
    path: '/sharebox/borrow',
    name: 'sharebox-borrow',
    component: () => import('@/views/ShareBox/components/ScanQR.vue'),

    meta: {
      action: ACTION_SCAN_QR.BORROW,
      layout: 'content',
    },
  },
  {
    path: '/sharebox/:id',
    name: 'sharebox-detail',
    component: () => import('@/views/ShareBox/components/ShareBoxDetail.vue'),
    meta: {
      layout: 'content',
    },
  },

  // ShareBox Staff
  {
    path: '/staff/sharebox',
    component: () => import('@/views/ShareBox/Staff/StaffList.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_share_box',
    },
    children: [
      {
        path: '',
        name: 'staff-sharebox',
        component: () => import('@/views/ShareBox/Staff/Borrowed.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'can-borrow',
        name: 'staff-sharebox-can-borrow',
        component: () => import('@/views/ShareBox/Staff/CanBorrow.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'edit/:id',
        name: 'staff-sharebox-edit',
        component: () => import('@/views/ShareBox/Staff/CanBorrow.vue'),
        meta: {
          layout: 'content',
        },
      },
    ],
  },
  {
    path: '/staff/sharebox/:id',
    name: 'staff-sharebox-detail',
    component: () => import('@/views/ShareBox/components/ShareBoxDetail.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_share_box',
    },
  },

  // Skill Share
  {
    path: '/skill-share',
    component: () => import('@/views/SkillShare/User/UserSkillShare.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
    children: [
      {
        path: '',
        name: 'skill-share-list',
        component: () => import('@/views/SkillShare/User/components/SkillShareList.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'calendar',
        name: 'skill-share-calendar',
        component: () => import('@/views/SkillShare/User/components/SkillShareCalendar.vue'),
        meta: {
          layout: 'content',
        },
      },
    ],
  },
  {
    path: '/skill-share/create',
    name: 'skill-share-create',
    component: () => import('@/views/SkillShare/User/CreateTopic.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },
  {
    path: '/skill-share/checkin',
    name: 'skill-share-checkin',
    component: () => import('@/views/SkillShare/User/Checkin.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },
  {
    path: '/skill-share/:id',
    name: 'skill-share-detail',
    component: () => import('@/views/SkillShare/User/DetailTopic.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },
  {
    path: '/staff/skill-share',
    name: 'staff-skill-share-list',
    component: () => import('@/views/SkillShare/Staff/StaffSkillShare.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_skill_share',
    },
  },
  {
    path: '/staff/skill-share/:id',
    name: 'staff-skill-share-detail',
    component: () => import('@/views/SkillShare/Staff/DetailTopic.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_skill_share',
    },
  },
  {
    path: '/staff/skill-share-edit/:id',
    name: 'staff-skill-share-edit',
    component: () => import('@/views/SkillShare/Staff/EditTopic.vue'),
    meta: {
      layout: 'content',
      permission: 'manage_town',
    },
    props: { edit: true },
  },

  // Playground
  {
    path: '/playground',
    name: 'playground',
    component: () => import('@/views/Playground/Camera.vue'),
    meta: {
      layout: 'content',
      authRequired: true,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },

  // Error Mobile
  {
    path: '/error-mobile',
    name: 'error-mobile',
    component: () => import('@/views/ErrorMobile.vue'),
    meta: {
      layout: 'blank',
    },
  },
]

export default routes
