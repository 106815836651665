import axios from '@/plugins/axios'
import UploadService from '@/services/UploadService'
import { ITEM_STATUS } from '@/utils/shareBoxHelper'
import store from '.'

export default {
  namespaced: true,
  state: {
    staffOverview: {
      available: 0,
      borrowed: 0,
      destroyed: 0,
      return_today: 0,
    },
    userOverview: {
      borrowed: 0,
      lend: 0,
    },
    isLoading: true,
    items: [],
    isMore: true,
    qrData: null,
    createItemData: {},
  },
  getters: {
    getStaffOverview(state) {
      return state.staffOverview
    },
    getUserOverview(state) {
      return state.userOverview
    },
    getLoading(state) {
      return state.isLoading
    },
    getItems(state) {
      return state.items
    },
    getIsMore(state) {
      return state.isMore
    },
    getQrData(state) {
      return state.qrData
    },
    getCreateItemData(state) {
      return state.createItemData
    },
  },
  mutations: {
    setStaffOverview(state, value) {
      state.staffOverview = value
    },
    setUserOverview(state, value) {
      state.userOverview = value
    },
    setLoading(state, value) {
      state.isLoading = value
    },
    setItems(state, value) {
      state.items = value
    },
    setIsMore(state, value) {
      state.isMore = value
    },
    setQrData(state, value) {
      state.qrData = value
    },
    setCreateItemData(state, value) {
      state.createItemData = value
    },
  },
  actions: {
    fetchStaffOverview({ commit }, params) {
      // commit('setLoading', true)

      return axios
        .get('/v1/shareBox/staff/overview', {
          params,
        })
        .then(resp => {
          const { result, data } = resp.data

          if (result === 'success') {
            commit('setStaffOverview', data)
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          // commit('setLoading', false)
        })
    },
    fetchUserOverview({ commit }, params) {
      // commit('setLoading', true)

      return axios
        .get('/v1/shareBox/user/overview', {
          params,
        })
        .then(resp => {
          const { result, data } = resp.data

          if (result === 'success') {
            commit('setUserOverview', data)
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          // commit('setLoading', false)
        })
    },
    fetchListItemReturnToDay({ commit }, params) {
      commit('setLoading', true)

      return axios
        .get('/v1/shareBox/item', {
          params: {
            page: 1,
            limit: 999,
            status: ITEM_STATUS.BORROWED,
            ...params,
          },
        })
        .then(resp => {
          const { result, data } = resp.data
          if (result === 'success') {
            return data
          }

          return []
        })
    },
    fetchListItem({ commit }, params) {
      commit('setLoading', true)

      return axios
        .get('/v1/shareBox/item', {
          params,
        })
        .then(resp => {
          const { result, data } = resp.data

          if (result === 'success') {
            commit('setItems', data)
          }
        })
        .catch(error => {
          console.log(error)

          // reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    loadMoreListItem({ commit, state }, params) {
      commit('setLoading', true)

      return axios
        .get('/v1/shareBox/item', {
          params,
        })
        .then(resp => {
          const { result, data } = resp.data
          if (result === 'success') {
            commit('setItems', [...state.items, ...data])
          }

          if (!data.length) {
            commit('setIsMore', false)
          }
        })
        .catch(error => {
          console.log(error)

          // reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    resetStore({ commit }) {
      commit('setItems', [])
      commit('setIsMore', true)
    },
    fetchUserInfoForCreate({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.qrData?.id) {
          reject(new Error('Qr invalid'))
        }
        axios
          .get('/v1/account/user/profile', {
            params: {
              id_user: state.qrData?.id,
            },
          })
          .then(resp => {
            const userData = resp.data.data
            commit('setCreateItemData', {
              ...state.createItemData,
              full_name: userData.full_name,
              id_owner: state.qrData?.id,
            })
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async createItem(ctx, bodyData) {
      try {
        // eslint-disable-next-line camelcase
        const { imgData, id_owner, title, description, returnDate } = bodyData

        const { result, files } = await UploadService.uploadFile({
          note: 'ShareBoxImage',
          files: imgData,
        }).then(res => res.data?.data)

        if (result === 'success') {
          const { data, result: resultCreate } = await axios
            .post('/v1/shareBox/item', {
              files: files?.map(item => item.id_file),
              id_owner,
              title,
              description,
              can_borrow_to: returnDate,
            })
            .then(res => res.data)

          if (resultCreate === 'success') {
            return Promise.resolve(data)
          }
          throw new Error('Create item fail. Try again')
        }
        throw new Error('Upload image fail !')

        // res.data?.data?.files?.[0]?.id_file
      } catch (error) {
        console.error(error)
        store.commit('snackbar/show', {
          message: error.message,
          type: 'error',
        })

        return Promise.reject(error)
      }

      // eslint-disable-next-line no-param-reassign

      // return axios.post(`/v1/infirmary/examination/${id}`, bodyData)
    },
    getItemInfo(ctx, itemId) {
      return axios.get(`/v1/shareBox/item/${itemId}`).then(res => res.data)
    },
    updateStatusItem(ctx, data) {
      const { status, idBorrow, itemId, returnAt } = data

      return axios
        .put(`/v1/shareBox/item/status/${itemId}`, {
          id_borrow: idBorrow,
          status,
          return_at: returnAt,
        })
        .then(res => res.data)
    },
    async updateItem(ctx, bodyData) {
      try {
        // eslint-disable-next-line camelcase
        const { imgData, description, title, id } = bodyData

        const { result, files } = await UploadService.uploadFile({
          note: 'ShareBoxImage',
          files: imgData,
        }).then(res => res.data?.data)

        if (result === 'success') {
          const { data, result: resultUpdate } = await axios
            .put(`/v1/shareBox/item/${id}`, {
              files: files?.map(item => item.id_file),
              description,
              title,
            })
            .then(res => res.data)

          if (resultUpdate === 'success') {
            return Promise.resolve(data)
          }
          throw new Error('Update item fail. Try again')
        }
        throw new Error('Upload image fail !')
      } catch (error) {
        console.error(error)
        store.commit('snackbar/show', {
          message: error.message,
          type: 'error',
        })

        return Promise.reject(error)
      }
    },
  },
}
