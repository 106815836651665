// axios
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_BASE_API_URL,
  headers: { 'Content-Type': 'application/json' },

  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken && config.url.includes('v1')) config.headers.Authorization = `${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  response => {
    if (response?.data?.result !== 'success' && response?.config?.responseType !== 'blob') {
      store.commit('snackbar/show', {
        message: response.data?.error?.description,
        type: 'error',
      })
    }

    return response
  },
  error => {
    // Log error
    if (error.response) {
      // get response with a status code not in range 2xx
      // console.log(error.response.data)
      // console.log(error.response.status)
      // console.log(error.response.headers)
      const message = error?.response?.data?.data?.message || error?.response?.data?.error?.description

      if (message !== 'Permission denied') {
        store.commit('snackbar/show', {
          message,
          type: 'error',
        })
      }
      if (message === 'Not found') {
        router.replace({ name: 'error-404' })
      }
    } else if (error.request) {
      // no response
      // console.log(error.request)
      store.commit('snackbar/show', {
        message: 'Không thể kết nối đến server',
        type: 'error',
      })
    } else {
      // Something wrong in setting up the request
      // console.log('Error', error.message)
      store.commit('snackbar/show', {
        message:
          'Có lỗi hệ thống xin vui lòng tải lại trang, nếu lỗi tiếp tục xuất hiện, xin vui lòng liên hệ chúng tôi',
        type: 'error',
      })
    }

    // console.log(error.config)

    return Promise.reject(error)
  },
)

Vue.prototype.$axios = axiosIns

export default axiosIns
