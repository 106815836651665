import axios from '@/plugins/axios'
import router from '@/router'
import Vue from 'vue'

const currentUserStored = localStorage.getItem('currentUser')

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken'),
    redirect: null,
    currentUser: currentUserStored && currentUserStored !== 'undefined' ? JSON.parse(currentUserStored) : null,
    roles: [],
  },
  getters: {
    isLoggedIn(state) {
      const { token } = state
      if (token) {
        // Token is present
        if (token.split('.').length === 3) {
          // Token with a valid JWT format XXX.YYY.ZZZ
          try {
            // Could be a valid JWT or an access token with the same format
            const base64Url = token.split('.')[1]
            const base64 = base64Url.replace('-', '+').replace('_', '/')
            const { exp } = JSON.parse(atob(base64))
            if (typeof exp === 'number') {
              // JWT with an optonal expiration claims
              return Math.round(new Date().getTime() / 1000) < exp
            }
          } catch (e) {
            return true // Pass: Non-JWT token that looks like JWT
          }
        }

        return true // Pass: All other tokens
      }

      return false
    },
    currentUser(state) {
      return state.currentUser
    },
  },
  mutations: {
    saveToken(state, { token, rememberMe }) {
      state.token = token
      if (rememberMe) {
        localStorage.setItem('accessToken', token)
      } else {
        sessionStorage.setItem('accessToken', token)
      }
    },
    setCurrentUser(state, userData) {
      Vue.set(state, 'currentUser', userData)
      localStorage.setItem('currentUser', JSON.stringify(userData))
    },
    clearToken(state) {
      state.token = null
      localStorage.removeItem('accessToken')
      sessionStorage.removeItem('accessToken')
    },
    setAuthRedirect(state, redirect) {
      state.redirect = redirect
    },
    setFamilyName(state, name) {
      Vue.set(state.currentUser, 'family_name', name)
    },
    setFamilyId(state, id) {
      Vue.set(state.currentUser, 'family_id', id)
    },
    setRoles(state, roles) {
      Vue.set(state, 'roles', roles)
    },
  },
  actions: {
    login({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/account/login', data)
          .then(async resp => {
            const result = resp?.data?.data

            // Login successful
            if (result?.token) {
              commit('saveToken', { token: result.token, rememberMe: data.rememberMe })
              await dispatch('getUserInfo', true)
            }
            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getUserInfo({ commit, state, dispatch }, redirect) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/account/user/profile')
          .then(resp => {
            const userData = resp.data.data
            if (resp.data.result !== 'fail') {
              commit('setCurrentUser', userData)
              if (redirect) {
                router.push(state.redirect || '/')
              }
            } else {
              dispatch('logout')
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    logout({ commit }) {
      commit('clearToken')
      router.push('/login')
    },
    getRoles({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/account/roles')
          .then(resp => {
            const roles = resp.data.data
            commit('setRoles', roles)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
