/* eslint-disable class-methods-use-this */
import axios from '@/plugins/axios'

class UploadService {
  // eslint-disable-next-line camelcase
  uploadFile({ note, files, is_face }) {
    return axios.post('/v1/file/upload', { note, files, is_face })
  }

  getListFiles(params) {
    return axios.get('/v1/file', {
      params,
    })
  }
}

export default new UploadService()
