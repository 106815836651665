<template>
  <div>
    <v-snackbar
      v-model="show"
      :timeout="time"
      :color="type === 'info' ? 'success' : type"
      top
    >
      <span class="text-block">{{ $t(message) }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-if="!to"
          color="blue"
          text
          v-bind="attrs"
          @click="show = false"
        >
          {{ $t('Close') }}
        </v-btn>
        <v-btn
          v-else
          color="blue"
          text
          v-bind="attrs"
          :to="to"
          @click="show = false"
        >
          {{ $t('View') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import NotiActionMap from '@/utils/notiActionMap'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      storeMessage: null,
      timer: null,
      show: false,
    }
  },
  computed: {
    ...mapGetters('snackbar', ['getMessage']),
    type() {
      return this.storeMessage?.type
    },
    message() {
      return this.storeMessage?.message
    },
    time() {
      return this.storeMessage?.time || 5000
    },
    to() {
      return NotiActionMap[this.storeMessage?.message]
    },
  },
  watch: {
    show(val) {
      if (!val) {
        this.$store.commit('snackbar/hide', this.storeMessage)
      }
    },
  },
  created() {
    this.storeMessage = _.cloneDeep(this.getMessage)
    clearInterval(this.timer)
    this.timer = setInterval(() => {
      if (!this.show) {
        this.storeMessage = _.cloneDeep(this.getMessage)
        if (this.storeMessage) {
          this.show = true
        }
      }
    }, 100)
  },
}
</script>
