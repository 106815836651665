export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    language: localStorage.getItem('language') || 'en',
  },
  getters: {},
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    CHANGE_LANGUAGE(state, value) {
      state.language = value !== undefined ? value : 'en'
      localStorage.setItem('language', state.language)
    },
  },
  actions: {},
}
