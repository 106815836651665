export default {
  // common
  LeGrandJardin: 'Exclusively for residents at Le Grand Jardin',
  Home: 'Home',
  'Second Page': 'Second Page',
  Welcome: 'Welcome to Hanoi Smart City!',
  Login: 'Login',
  RememberMe: 'Remember Me',
  ForgotPassword: 'Forgot password',
  Other: 'Other',
  Submit: 'Submit',
  Back: 'Back',
  Close: 'Close',
  Details: 'Details',
  Information: 'Information',
  Family: 'User Group',
  Role: 'Role',
  User: 'User',
  Nurse: 'Nurse',
  Staff: 'Staff',
  Admin: 'Admin',
  Status: 'Status',
  Active: 'Active',
  Inactive: 'Inactive',
  Activity: 'Activity',
  Check: 'Check',
  Edit: 'Edit',
  ChooseImage: 'Choose Image',
  Accept: 'Accept',
  Join: 'Join',
  Leave: 'Leave',
  Deny: 'Deny',
  Reset: 'Reset',
  at: 'at',
  AreYouSure: 'Are you sure?',
  Resend: 'Resend',
  ResendAfterTime: 'Resend after {time}s',
  NoData: 'No Data',
  Next: 'Next',
  Building: 'Building',
  RoomNumber: 'Room Number',
  Month: 'Month',
  Week: 'Week',
  Day: 'Day',
  Continue: 'Continue',
  Image: 'Image',
  Approve: 'Approve',
  Reject: 'Reject',
  Delete: 'Delete',
  DeleteExamination: 'Delete examination',
  DeleteExaminationContent: 'Are you sure to delete this examination?',

  LoginSubtitle: 'Please sign-in to your account',
  LoginSuggestion: 'Already have an account?',
  BackToLogin: 'Back to login',
  RegisterTitle: 'Register user',
  RegisterSubtitle: 'Please enter your phone number correctly, we will send OTP to confirm.',
  RegisterSuggestion: 'New on our platform?',
  AgreeToTerms: 'I agree to',
  TermsOfService: 'terms of service',
  BackToRegister: 'Back to register',
  CreateAccount: 'Create an account',
  ConfirmOTP: 'OTP Confirmation',
  ConfirmOTPSubtitle: 'OTP was sent to {phoneNumber}. You should check your phone to get OTP',
  EnterOTP: 'Enter OTP',
  ResendOTP: 'Resend OTP',
  UpdatePassword: 'Update Password',
  UpdatePasswordSubtitle:
    'Thank you for completing the registration process. Please provide your password for the next login',
  ForgotPasswordSubtitle: 'Please enter your registered phone number',
  UpdatePhoneNumber: 'Update Phone Number',
  UpdatePhoneNumberSuccessMsg: 'Your phone number has been updated',
  UpdatePasswordSuccessMsg: 'Your password has been updated',
  BackToProfile: 'Back to profile',
  CreateNewFamily: 'Create new Group',
  LeaveFamily: 'Leave Group',
  JoinFamily: 'Join Group',
  NoFamilyMsg: 'Do not belong to any Group',
  FamilyInvitationTitle: 'Are you sure?',
  FamilyInvitationSubtitle:
    'You are going to join the group of {name} with the role as {role}. If you agree, our system will decline all other invitations automatically',
  FamilyInvitationsSubtitle: 'You have {count} invitation(s) to join the Group',
  FamilyInvitationsMsg:
    "{name}'s phone number is {phoneNumber}, and he/her invites you to become a {role} in his/her group",
  WaitingToAccept: 'Waiting to Accept',
  Invite: 'Invite',
  WithPhone: 'With Phone',
  WithoutPhone: 'Without Phone',
  ChildHasAccount: 'Relationship',
  ChildInformation: 'Your child information',
  Timeline: 'Timeline',

  Password: 'Password',
  CurrentPassword: 'Current password',
  NewPassword: 'New password',
  ConfirmPassword: 'Confirm password',
  PhoneNumber: 'Phone number',
  CurrentPhoneNumber: 'Current Phone number',
  NewPhoneNumber: 'New Phone number',
  Fullname: 'Fullname',
  Gender: 'Gender',
  Male: 'Male',
  Female: 'Female',
  Age: 'Age',
  FaceImage: 'Face image',
  FaceWithMaskImage: 'Face with mask image',
  FamilyName: 'Group name',
  YourFamily: 'Your group',
  YearOfBirth: 'Year of birth',
  ListMembers: 'List Members',
  AddMember: 'Add Member',
  TypeMember: 'Type of Member',

  UsersManagement: 'Users Management',
  ListUsers: 'List Users',
  UserInformation: 'User Information',
  SearchByPhoneOrName: 'Type phone or name to search',
  UpdateInfo: 'Update Information',
  LockUser: 'Lock User',
  UnlockUser: 'Unlock User',
  AreYouSureToLockUser: 'Are you sure to lock this user?',
  AreYouSureToUnlockUser: 'Are you sure to unlock this user?',
  ResetPassword: 'Reset Password',
  DeleteUser: 'Delete User',
  AreYouSureToDeleteUser: 'Are you sure to delete this user?',
  RemoveMember: 'Remove Member',
  UserHasLockedMsg: 'User was locked by {name}',

  AreYouSureToLeave: 'Are you sure to leave this group?',

  // TownInfirmary
  'Town Infirmary': 'Health check-up',
  InfirmaryListUser: 'List user wait physical examination',
  Search: 'Search',
  RefreshData: 'Refresh Data',
  CheckIn: 'Check In',
  CheckUp: 'Check Up',
  CheckUpAt: 'Check Up At',
  PhoneOrName: 'Type phone or name',
  ListExamTitle: 'List user physical examination',

  PhysicalExamination: 'Physical Examination',
  Name: 'Name',
  Remove: 'Remove',
  Wait: 'Wait',
  Waiting: 'Waiting',
  Height: 'Height',
  Weight: 'Weight',
  BMI: 'BMI',
  BloodPressure: 'Blood Pressure',
  BodyFat: 'Body Fat',
  MuscleMass: 'Muscle Mass',
  DateMeasurement: 'Date Measurement',
  Save: 'Save',
  Cancel: 'Cancel',
  SaveSuccess: 'Save success',
  RequiredFillData: 'Please, fill full information',
  RemoveSuccess: 'Remove success',

  Confirm: 'Confirm',
  ScanQR: 'Scan QR code to check in',
  CheckInConfirm: 'Are you sure check-in phone number',
  No: 'No',
  Yes: 'Yes',

  HealthInformation: 'Health information',
  You: 'You',
  Child: 'Child',
  Parent: 'Parent',
  LastPhysicalExamination: 'Last Physical Examination',
  From: 'From',
  To: 'To',
  HealthCheckOn: 'Health check on',
  PleaseUpload: 'Please upload your face image',

  // Cafe
  'Point Section': 'Point Section',
  View: 'View',
  ViewActivity: 'View Activity',
  WelcomeStaffName: 'Welcome',
  Orders: 'Orders',
  Coupon: 'Coupon',
  CouponScannedToday: 'Coupon Scanned Today',
  ViewCoupon: 'View coupon',
  ScanCreateItem: 'Scan the QR code of user to get information',
  ScanReturnItem: 'Scan the QR code of item',
  ScanBorrowItem: 'Scan the QR code of the item you will borrow',
  'Return item': 'Return item',
  'Are you sure you want to return this item': 'Are you sure you want to return this item',
  'Back list': 'Back list',
  'Are you sure you want to back list this item': 'Are you sure you want to back list this item',

  ScanCoupon: 'Scan QR coupon',
  CustomerName: 'Customer name',
  CustomerId: 'Customer Id',
  OrderID: 'Bill ID',
  CouponConfirm: 'Confirm the use of the coupon code',
  Date: 'Date',
  ActivityOf: 'Activity of',
  StaffScanPoint: 'Scan QR code for customer',
  ScanPoint: 'Scan QR code coupon',
  StaffScanCoupon: 'has been used by Customer',
  StaffBillID: 'with Bill code',
  StaffCouponCode: 'and Coupon code',
  WithOrderNumber: 'with order number',
  History: 'History',
  ExpirationDate: 'Expiration date',
  Point: 'Point',
  Change: 'Change',
  PleaseGiveCodeCoupon: 'Please give this code to the staff when you want to use it',
  CouponList: 'Coupon List',
  UsedDate: 'Used Date',
  NotExprired: 'Not Exprired',
  Expired: 'Expired',
  Used: 'Used',
  NotUsed: 'Not Used',

  // Sharebox
  'Share Box': 'Smart share counter',
  'Share Box Management': 'Smart share counter Management',
  'See all available items': 'See all available items',
  'Scan QR code': 'Borrow',
  Borrowing: 'Borrowing',
  Lending: 'Lending',
  Borrow: 'Borrow',
  BackList: 'Back List',
  Return: 'Return',
  YourItem: 'Your Item',
  ListOfItemsYouBorrow: 'List of items you borrow',
  ListOfItemsCanBeBorrowed: 'List of items can be borrowed',
  ListOfItemsYouLend: 'List of items you lend',
  ItemsNeedToBeReturnedToday: 'Items need to be returned today',
  ReturnTime: 'Return time',
  ReturnTimeNow: 'Return time',
  ItemHasBeenBorrowed: 'Item has been borrowed',
  ItemHasNotBeenBorrowed: 'Item has not been borrowed',
  CanBorrowTo: 'Can borrow to',
  ViewQRCode: 'View QR code',
  Owner: 'Owner',
  SentTime: 'Sent time',
  Description: 'Description',
  BorrowItem: 'Borrow Item',
  SuccessfullyRegistered: 'You have successfully registered to borrow this item.',
  ShowThisQR: 'Please show this QR Code to the staff to pick up the items',
  ReturnBefore: 'Please return the item before time',
  BeingBorrowedBy: 'Being borrowed by',
  Phone: 'Phone',
  ID: 'ID',
  Gallery: 'Gallery',
  ReadyToBorrow: 'Items ready to borrow',
  BeingBorrowed: 'Item is being borrowed',
  ItemsReturnToday: 'Items to be return today',
  CreateNewItem: 'Create New Item',
  ReturnItem: 'Return Item',
  AvailableItems: 'Available Items',
  ListItemBorrowed: 'List of Item is being borrowed',
  BorrowedItems: 'Borrowed Items',
  ListItemsReadyBorrow: 'List of items ready to borrow',
  Add: 'Add',
  BorrowTime: 'Borrow time',
  NoContent: 'Content will be available soon',
  'Provide item from residents': 'Provide item from residents',
  'Borrow the item': 'Borrow the item',

  // Play ground
  Playground: 'Smart playground',
  NoMembersInPlaygroundMsg: 'There are currently no members of your User Group in Play Ground!',
  'See security camera': 'See security camera',
  PlaygroundNoti: 'Resident {id_user} from group {family_name} has just entered the Smart PlayGround',
  PlaygroundNotiNoGroup: 'Resident {id_user} has just entered the Smart PlayGround',

  // activity_types
  login: 'Login',
  change_password: 'Change Password',
  scan_add_order: 'Scan QRCode Order',
  leave_family: 'Leave Group',
  exchange_coupon: 'Exchange Coupon',
  use_coupon: 'Use Coupon',
  change_phone: 'Change Phone Number',
  update_user: "Update user's information",

  // SkillShare
  SkillShare: 'Skill Share',
  ListTopic: 'List Topic',
  TopicName: 'Topic Name',
  Speaker: 'Speaker',
  Time: 'Time',
  Participants: 'Participants',
  CheckinList: 'Checkin List',
  All: 'All',
  Joined: 'Joined',
  Approved: 'Approved',
  WaitApproval: 'Wait Approval',
  NotJoin: 'Not Join',
  Rejected: 'Rejected',
  SkillShareActionsMsg: 'Do you want to create a sharing session or book a reservation?',
  WaitStaffApproveMsg: 'Please wait until staff approve this topic',
  CreateSkillShare: 'Create Skill share',
  BookReservation: 'Book Reservation',
  CancelSharing: 'Cancel sharing',
  DeleteSharing: 'Delete sharing',
  CancelReservation: 'Cancel reservation',
  AreYouSureToCancelTopic: 'Are you sure to cancel this sharing?',
  AreYouSureToDeleteTopic: 'Are you sure to delete this sharing?',
  AreYouSureToRejectTopic: 'Are you sure to reject this sharing?',
  AreYouSureToApproveTopic: 'Are you sure to approve this sharing?',
  AreYouSureToLeaveTopic: 'Are you sure to leave this sharing?',
  AreYouSureToJoinTopic: 'Are you sure to join this sharing?',
  RegisterSkillShare: 'Register Skill Share',
  AgreeTermsSharing: 'I agree to the terms and will be responsible for my sharing',
  Regulations: 'Regulations',
  RegulationsUpdate: 'Content will be available soon',
  ChooseTimeRange: 'Choose Time Range',
  StartTime: 'Start time',
  EndTime: 'End time',
  ExpectedParticipants: 'Expected Participants',
  Topic: 'Topic',
  ScanTopicToCheckin: 'Scan QR code of topic to checkin',
  Checkin: 'Checkin',
  SearchByQrCode: 'Search by QR Code',
  PrintQr: 'Print QR Code',
  'Joining skill share': 'Joining skill share',
  'Hold the skill share': 'Hold the skill share',

  // Notification msgs
  account_001: 'You have an invitation to group',
  account_002: 'You received coupon',
  town_infirmary_001: 'Check-in success',
  cafe_002: 'coupon was used successfully',
  cafe_003: 'coupon is about to expire',
  sharebox_001: 'You received coupon',
  general_001:
    'Lớp yoga miễn phí, voucher cafe 50.000 VND và quà hấp dẫn trong ngày 13.03.2022 từ lúc 14h. Đến ngay Smart Life Station!',
  sharebox_003: 'Your item has been lent',
  share_box_001: 'You received coupon',
  share_box_003: 'Your item has been lent',
  skillshare_001: 'Your topic has been approved',
  skillshare_002: 'You have successfully checked in',
  skillshare_003: 'You received coupon',
  skill_share_001: 'Your topic has been approved',
  skill_share_002: 'You have successfully checked in',
  skill_share_003: 'You received coupon',

  // Server
  'Not found': 'Not found',
  'Permission denied': 'Permission denied',
  'Coupon expired': 'Coupon expired',
  'Already check in': 'Already check in',
  'Already return to owner': 'Already return to owner',
  'Wrong status': 'Wrong status',
  'Already borrowed': 'Already borrowed',
  'Missing id borrow': 'Missing id borrow',
  'Missing return_at': 'Missing return_at',
  'No data': 'No data',
  'Out of seat': 'Out of seat',
  'You cannot delete yourself': 'You cannot delete yourself',
  'You cannot lock yourself': 'You cannot lock yourself',
  'You cannot assign yourself': 'You cannot assign yourself',
  Hi: 'Hi',
  Logout: 'Logout',
}
