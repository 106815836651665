import {
  formatDate,
  formatISOStringToDatetime,
  formatISOStringToTimestamp,
  formatTimestampToDatetime,
  isToday,
  timeFromNow,
} from '@/utils/datetimeHelper'
import themeConfig from '@themeConfig'

const globalMixins = {
  computed: {
    appLogo() {
      return this.$vuetify.theme.isDark ? themeConfig.app.logoDark : themeConfig.app.logo
    },
    yearsOfBirth() {
      const currentYear = new Date().getFullYear()
      const years = Array.from(new Array(100), (_, idx) => currentYear - idx)

      return years
    },
  },
  methods: {
    formatDate,
    formatTimestampToDatetime,
    formatISOStringToDatetime,
    formatISOStringToTimestamp,
    timeFromNow,
    isToday,
  },
}

export default globalMixins
