/* eslint-disable operator-linebreak */
import dayjs from 'dayjs'

const relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(relativeTime)

export const formatDate = (time, format = 'DD/MM/YYYY') => {
  if (time && time !== undefined && time !== null) {
    return dayjs(time)
      .locale('vi')
      .format(format)
  }

  return ''
}

export const formatTimestampToDatetime = (time, format = 'DD/MM/YYYY HH:mm:ss') => {
  if (time && time !== undefined && time !== null) {
    return dayjs
      .unix(time)
      .locale('vi')
      .format(format)
  }

  return ''
}

export const formatISOStringToTimestamp = time => {
  if (time && time !== undefined && time !== null) {
    return dayjs(time).unix()
  }

  return ''
}

export const formatISOStringToDatetime = (time, format = 'DD/MM/YYYY HH:mm:ss') => {
  if (time && time !== undefined && time !== null) {
    return dayjs(time)
      .locale('vi')
      .format(format)
  }

  return ''
}

export const timeFromNow = time => {
  if (time && time !== undefined && time !== null) {
    return dayjs(dayjs.unix(time).locale('vi')).fromNow()
  }

  return ''
}

export const isToday = (val1, val2) => {
  if (val1 && val2) {
    return (
      val1.getFullYear() === val2.getFullYear() &&
      val1.getMonth() === val2.getMonth() &&
      val1.getDate() === val2.getDate()
    )
  }

  return ''
}

const datetimeHelpers = {
  formatDate,
  formatTimestampToDatetime,
  formatISOStringToDatetime,
  timeFromNow,
}

export default datetimeHelpers
