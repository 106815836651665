import '@/plugins/axios'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import * as filters from './@core/utils/filter'
import App from './App.vue'
import globalMixins from './mixins/global'
import './plugins/vue-image-lightbox'
import './plugins/vue-infinite-scroll'
import './plugins/vue-pusher'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.mixin(globalMixins)

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
