<template>
  <div id="tac">
    <h3 class="text-center mb-4">
      CÁC ĐIỀU KHOẢN VÀ ĐIỀU KIỆN
    </h3>
    <h4 class="text-center">
      PHẦN I <br />
      ĐIỀU KHOẢN VÀ ĐIỀU KIỆN CHUNG ĐỐI VỚI DỊCH VỤ
    </h4>
    <ol class="pl-0 mt-4">
      <li>
        <b>1. Áp Dụng Chung</b>
        <ul>
          <li>(a) Bằng việc đăng ký Dịch vụ Thông Minh Smart Life Station (“<b>Các Dịch Vụ</b>”), nội dung của Các Dịch Vụ được định nghĩa và nêu chi tiết tại Phần II và các phần sau đó, và việc tạo một tài khoản thông qua ứng dụng này được thiết kế dành riêng cho Các Dịch Vụ (“<b>Ứng Dụng</b>”), cư dân xác nhận rằng cư dân đủ tuổi hợp pháp và có năng lực hành vi dân sự đầy đủ để giao kết thỏa thuận này (“<b>Thỏa Thuận</b>”) với SUMITOMO CORPORATION (sau đây gọi là “<b>chúng tôi</b>”, “<b>của chúng tôi</b>”, hoặc “<b>Công Ty</b>” tùy ngữ cảnh yêu cầu), cư trú hợp pháp tại Việt Nam, và cư dân chấp nhận và chịu sự ràng buộc bởi các điều khoản và điều kiện quy định tại Phần I và các điều khoản và điều kiện áp dụng cho một trong Các Dịch Vụ quy định tại Phần II và các phần sau đó (gọi chung là “<b>Các Điều Khoản Và Điều Kiện</b>”).  Nếu cư dân không đáp ứng tất cả các yêu cầu này, cư dân không được truy cập hoặc sử dụng Ứng Dụng hoặc Các Dịch Vụ.</li>
          <li>(b) Cư dân không sử dụng Ứng Dụng hoặc Các Dịch Vụ nếu (i) cư dân không đồng ý với Các Điều Khoản Và Điều Kiện này, (ii) cư dân không đáp ứng các yêu cầu năng lực như được quy định tại điểm (a) ở trên, hoặc (iii) cư dân theo pháp luật bị cấm sử dụng Ứng Dụng hoặc bất kỳ dịch vụ nào trong số Các Dịch Vụ.</li>
          <li>
            (c) Các Điều Khoản Và Điều Kiện này phải được đọc cùng với Chính Sách Bảo Mật quy định dưới đây trước khi sử dụng Các Dịch Vụ và đăng ký tài khoản của cư dân thông qua Ứng Dụng.
          </li>
        </ul>
      </li>
      <li>
        <b>2. Đăng Ký</b>
        <ul>
          <li>(a) Nếu cư dân muốn sử dụng Các Dịch Vụ, cư dân có thể tạo một tài khoản riêng của cư dân thông qua Ứng Dụng và đăng ký sử dụng Các Dịch Vụ bằng việc đồng ý tuân thủ Thỏa Thuận này và cung cấp một số thông tin như quy định của Công Ty, theo cách thức mà Công Ty quy định.</li>
          <li>(b) Khi hoàn thành việc tạo tài khoản và đăng ký theo đoạn trên, Thỏa Thuận này sẽ được ký kết theo Các Điều Khoản Và Điều Kiện này giữa cư dân và chúng tôi, cho phép cư dân sử dụng Ứng Dụng và Các Dịch Vụ theo Các Điều Khoản Và Điều Kiện. </li>
          <li>
            (c) Cư dân phải thông báo ngay cho chúng tôi bất kỳ thay đổi nào đối với các thông tin mà cư dân đã cung cấp khi đăng ký theo cách thức mà chúng tôi quy định, nếu không các thông tin đã đăng ký sẽ được xem là thông tin mới nhất và chính xác.
          </li>
          <li>
            (d) Cư dân không được tạo tài khoản riêng của cư dân và đăng ký sử dụng Các Dịch Vụ, và chúng tôi có thể từ chối việc tạo tài khoản và/hoặc chấm dứt Thỏa Thuận này theo Mục 13.(a) trong Phần I, mà không có nghĩa vụ phải tiết lộ lý do, nếu:
            <ul class="none">
              <li>(i) cư dân không sống tại Le Grand Jardin;</li>
              <li>(ii) tất cả hoặc bất kỳ phần nào thông tin do cư dân cung cấp cho chúng tôi khi tạo tài khoản và đăng ký bị phát hiện là sai, không đúng hoặc bị thiếu sót;</li>
              <li>(iii) cư dân là người chưa thành niên, người có khó khăn trong nhận thức, làm chủ hành vi hoặc người được trông nom hoặc hỗ trợ, và chưa có chấp thuận hoặc đồng ý tương ứng từ người đại diện theo pháp luật, người giám hộ, người trông nom hoặc người hỗ trợ của cư dân; </li>
              <li>(iv) chúng tôi xác định rằng cư dân (i) thành lập một nhóm tội phạm có tổ chức hoặc một thành viên của nhóm tội phạm có tổ chức đó, tổ chức phản động, cực hữu, lực lượng chống đối xã hội hoặc là cá nhân hoặc tổ chức tương tự khác (gọi chung là “<b>Lực Lượng Chống Đối Xã Hội</b>”), hoặc (ii) có bất kỳ sự tương tác hoặc liên quan nào đến một Lực Lượng Chống Đối Xã Hội dưới bất kỳ hình thức nào như hỗ trợ hoặc tham gia vào việc duy trì, hoạt động hoặc quản lý Lực Lượng Chống Đối Xã Hội bằng việc cung cấp tài chính hoặc các phương thức khác;</li>
              <li>(v) chúng tôi xác định rằng cư dân là một bên đã vi phạm bất kỳ hợp đồng nào với chúng tôi hoặc có liên quan đến các bên vi phạm đó; hoặc</li>
              <li>(vi) ngoài những trường hợp trên, chúng tôi cho rằng việc đăng ký không phù hợp.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <b>3. Mật Khẩu và Quản Lý Tên Đăng Nhập</b>
        <ul>
          <li>
            (a) Cư dân phải có trách nhiệm lưu giữ và duy trì mật khẩu và tên đăng nhập của cư dân cho Ứng Dụng và Các Dịch Vụ theo cách thức phù hợp và không được để một bên thứ ba sử dụng, hoặc cung cấp, chuyển giao, thay đổi tên, hoặc bán hoặc xử lý bằng cách khác, mật khẩu và tên đăng nhập.
          </li>
          <li>(b) Cư dân sẽ, và chúng tôi sẽ không, chịu trách nhiệm đối với bất kỳ thiệt hại nào phát sinh từ việc quản lý không phù hợp, sử dụng sai, hoặc sử dụng bởi một bên thứ ba đối với mật khẩu hoặc tên đăng nhập của cư dân.  Việc bất kỳ bên thứ ba nào sử dụng Ứng Dụng hoặc Các Dịch Vụ bằng mật khẩu và tên đăng nhập của cư dân sẽ được xem như là cư dân sử dụng.</li>
        </ul>
      </li>
      <li><b>4. Giấy Phép</b> Sau khi cư dân đăng ký như trên, chúng tôi theo đây cấp cho cư dân một giấy phép sử dụng phần mềm không riêng biệt, không thể chuyển nhượng, không thể cấp phép lại và có thể hủy ngang, để tải xuống và sử dụng Ứng Dụng trên thiết bị riêng của cư dân trong suốt thời hạn của Thỏa Thuận này (“<b>Thời Hạn</b>”) chỉ cho mục đích sử dụng Các Dịch Vụ theo Các Điều Khoản Và Điều Kiện này tại Việt Nam.</li>
      <li>
        <b>5. Các Dịch Vụ</b> Sau khi cư dân đăng ký như trên, cư dân có quyền vào không gian cho Các Dịch Vụ (“<b>Không Gian</b>”) được chuẩn bị bởi chúng tôi trong Le Grand Jardin với Ứng Dụng và sử dụng Các Dịch Vụ trong suốt Thời Hạn.  Hiện tại, Các Dịch Vụ đang được cung cấp miễn phí, trừ khi cư dân mua thức ăn và đồ uống trong Không Gian, và cư dân có thể sử dụng Các Dịch Vụ chỉ cho mục đích phi thương mại, sử dụng cá nhân, bằng rủi ro và chi phí riêng của cư dân.  Phạm vi và các quy định của Các Dịch Vụ được quy định từ Phần II đến V của Các Điều Khoản Và Điều Kiện này.
      </li>
      <li>
        <b>6. Thời Hạn</b> Các Dịch Vụ sẽ được cung cấp như một thử nghiệm giới thiệu dịch vụ của chúng tôi chỉ dành cho các cư dân của Le Grand Jardin đã đăng ký và tạo một tài khoản thông qua Ứng Dụng trong một khoảng thời gian giới hạn từ ngày 13 tháng 02 năm 2022 đến ngày 06 tháng 05 năm 2022, và thời gian này có thể thay đổi theo toàn quyền quyết định của chúng tôi.  Thời Hạn sẽ bắt đầu từ ngày đăng ký của cư dân và tự động kết thúc khi thời hạn thử nghiệm giới thiệu kết thúc.
      </li>
      <li>
        <b>7. Các Hành Động Bị Cấm</b> Khi sử dụng Các Dịch Vụ, cư dân không được thực hiện hoặc khiến một bên thứ ba thực hiện bất kỳ hành động nào mà thuộc, hoặc được chúng tôi xác định là thuộc, bất kỳ trường hợp nào sau đây:
        <ul>
          <li>
            (i) vi phạm pháp luật áp dụng hoặc xâm phạm các quyền của người khác.  Điều này bao gồm việc truyền tải bất kỳ thông tin hoặc dữ liệu nào thuộc, hoặc được chúng tôi xác định là thuộc, thông tin hoặc dữ liệu mà:
            <ul>
              <li>- chứa đựng nội dung quá bạo lực hoặc độc hại;</li>
              <li>- chứa đựng các vi-rút máy tính hoặc các chương trình máy tính nguy hiểm khác;</li>
              <li>- chứa đựng nội dung gây thiệt hại danh tiếng hoặc sự tín nhiệm của chúng tôi, hoặc những người sử dụng khác của Các Dịch Vụ hoặc các bên thứ ba khác;</li>
              <li>- chứa đựng nội dung quá khiếm nhã;</li>
              <li>- chứa đựng nội dung khuyến khích phân biệt đối xử;</li>
              <li>- chứa đựng nội dung khuyến khích tự sát hoặc tự làm hại bản thân;</li>
              <li>- chứa đựng nội dung lạm dụng thuốc;</li>
              <li>- chứa đựng nội dung chống đối xã hội;</li>
              <li>- được dự định để phân phát cho các bên thứ ba, bao gồm thư điện tử dây chuyền; hoặc</li>
              <li>- chứa đựng nội dung khiến các bên thứ ba cảm thấy không thoải mái.</li>
            </ul>
          </li>
          <li>(ii) xâm phạm quyền sở hữu trí tuệ của chúng tôi hoặc bất kỳ bên thứ ba nào (ví dụ, bản quyền, bằng sáng chế, nhãn hiệu, quyền mô hình/giải pháp hữu ích, quyền thiết kế), bao gồm cả bất kỳ sự sao chép, bản dịch, thay đổi, phân phối hoặc đăng tải nào đối với Ứng Dụng;</li>

          <li>(iii) tạo ra, tiết lộ, trưng bày, truyền tải, cung cấp hoặc đăng tải thông tin chứa đựng vi-rút máy tính hoặc bất kỳ phần mềm, chương trình, tập tin hoặc mã máy tính khác nhằm cản trở hoặc phá hủy chức năng của phần mềm máy tính, phần cứng hoặc thiết bị viễn thông; </li>
          <li>(iv) truyền tải hoặc đăng thông tin sai lệch;</li>
          <li>(v) bất kỳ hành động quảng cáo thương mại nào hoặc bất kỳ mục đích thương mại nào khác không tuân theo Các Điều Khoản Và Điều Kiện này.  Hành động này bao gồm việc chuyển hướng, bán, sử dụng, cho thuê, sao chép hoặc điều chỉnh tất cả hoặc một phần Các Dịch Vụ hoặc Ứng Dụng, giải mã Ứng Dụng hoặc bất kỳ nỗ lực nào khác để thiết kế đảo ngược;</li>
          <li>(vi) thực hiện khảo sát, thu thập thông tin mà không có sự đồng ý của bên khác, các cuộc thi, mô hình kim tự tháp, thư dây chuyền, v,v;</li>
          <li>
            (vii) cản trở việc sử dụng Các Dịch Vụ của những người sử dụng khác hoặc cản trở việc vận hành Các Dịch Vụ của chúng tôi.  Hành động này bao gồm can thiệp hoặc làm gián đoạn các máy chủ hoặc mạng cung cấp Các Dịch Vụ;
          </li>
          <li>(viii) sử dụng trái phép thông tin của những người sử dụng khác;</li>
          <li>
            (ix) bất kỳ hành động nào gây hại cho người chưa thành niên;
          </li>
          <li>(x) mạo danh một bên thứ ba;</li>
          <li>(xi) các hành động phi đạo đức, thô tục, có hại, khiếm nhã, không phù hợp hoặc làm người khác, bao gồm những người sử dụng khác, khó chịu; hoặc</li>
          <li>(xii) vào Không Gian cùng với thú cưng hoặc bất kỳ động vật nào khác, gây thiệt hại hoặc làm bẩn bất kỳ cơ sở vật chất hoặc hàng hóa nào trong Không Gian, hoặc bất kỳ hành động nào khác trái với hướng dẫn của chúng tôi được thông báo hoặc đăng riêng thông qua Ứng Dụng hoặc trong Không Gian.</li>
        </ul>
      </li>
      <li><b>8. Quyền Sở Hữu Trí Tuệ</b> Bất kỳ bản quyền, bằng sáng chế, quyền mô hình/giải pháp hữu ích, quyền thiết kế, quyền nhãn hiệu và các quyền nhãn hiệu khác và quyền sở hữu trí tuệ khác (bao gồm cả các quyền xin bảo hộ, hoặc nộp đơn đăng ký các quyền đó) liên quan đến Ứng Dụng và Các Dịch Vụ được bảo lưu rõ ràng bởi chúng tôi hoặc các bên cấp phép của chúng tôi.</li>
      <li><b>9. Bảo Mật</b> Cư dân phải giữ bí mật bất kỳ thông tin không công khai nào liên quan đến Các Dịch Vụ do chúng tôi tiết lộ cho cư dân mà khi tiết lộ chúng tôi yêu cầu cư dân bảo mật, trừ khi cư dân đã có được chấp thuận trước bằng văn bản của chúng tôi.</li>
      <li><b>10. Dữ Liệu</b> Chúng tôi có thể xử lý hoặc tiết lộ cho các bên thứ ba tất cả dữ liệu được cung cấp bởi cư dân hoặc thiết bị của cư dân thông qua Các Dịch Vụ hoặc Ứng Dụng (“<b>Dữ Liệu</b>”) cho hoạt động kinh doanh riêng của chúng tôi theo toàn quyền quyết định của chúng tôi; tuy nhiên, chúng tôi sẽ xử lý hoặc tiết lộ thông tin cá nhân của cư dân theo pháp luật áp dụng và theo Chính Sách Bảo Mật của chúng tôi được quy định riêng.  Cư dân theo đây đồng ý việc chúng tôi xử lý dữ liệu cá nhân của cư dân theo Chính Sách Bảo Mật của chúng tôi.</li>
      <li>
        <b>11. Miễn Trừ và Giới Hạn Trách Nhiệm</b>
        <ul class="style_abc">
          <li>(a) Chúng tôi không bảo đảm, bất kể rõ ràng hoặc ngầm hiểu, (i) rằng Các Dịch Vụ, Ứng Dụng, và các sản phẩm và dịch vụ khác được cung cấp hoặc thuê liên quan đến Các Dịch Vụ, bao gồm cả hệ thống nhận diện khuôn mặt ghi hình những người sử dụng Các Dịch Vụ trong khu vực cung cấp Các Dịch Vụ nhằm xác thực danh tính của cư dân và bảo đảm Các Dịch Vụ được cung cấp một cách an toàn (gọi chung là “<b>Các Dịch Vụ Và Sản Phẩm</b>”), thích hợp hoặc phù hợp cho một mục đích cụ thể dự liệu bởi cư dân, (ii) rằng Các Dịch Vụ Và Sản Phẩm có các chức năng, giá trị thương mại, độ chính xác hoặc tính hữu dụng như mong đợi, (iii) rằng việc sử dụng Các Dịch Vụ Và Sản Phẩm bởi cư dân tuân thủ pháp luật và quy định, (iv) rằng Các Dịch Vụ Và Sản Phẩm sẽ không bị gián đoạn, lỗi, khiếm khuyết hoặc vi phạm quyền sở hữu trí tuệ do các bên thứ ba nắm giữ, hoặc (v) Dữ Liệu sẽ không bị rò rỉ, hoặc sẽ không xảy ra vi phạm tiết lộ dữ liệu hoặc bất kỳ sự cố an ninh mạng nào khác  trong hệ thống của Các Dịch Vụ Và Sản Phẩm. </li>
          <li>(b) Nếu cư dân muốn sử dụng các dịch vụ của các công ty đối tác của chúng tôi và các công ty khác cung cấp thông tin hoặc các dịch vụ liên quan đến Ứng Dụng hoặc Các Dịch Vụ, cư dân sẽ tự chịu rủi ro khi sử dụng các dịch vụ đó, và truy cập và sử dụng các trang điện tử của các công ty đối tác của chúng tôi và các công ty khác theo các điều khoản và điều kiện được quy định trên các trang điện tử đó.  Chúng tôi không kiểm soát các trang điện tử khác liên kết với Ứng Dụng hoặc Các Dịch Vụ và không chịu trách nhiệm đối với bất kỳ thay đổi hoặc cập nhật trên các trang điện tử đó hoặc việc chấm dứt các dịch vụ của họ.  Chúng tôi sẽ không chịu trách nhiệm đối với bất kỳ tổn thất hoặc thiệt hại nào mà cư dân hoặc bất kỳ bên thứ ba nào gánh chịu có liên quan đến việc cư dân sử dụng các trang điện tử khác liên kết với Ứng Dụng hoặc Các Dịch Vụ.  Ngoài ra, chúng tôi không đảm bảo tính đầy đủ, chính xác, chắc chắn, hữu dụng, hoặc bất kỳ khía cạnh nào khác của các sản phẩm, các dịch vụ, hoặc thông tin được cung cấp bởi các công ty đối tác của chúng tôi.</li>
          <li>(c) Cư dân có thể sử dụng thông tin có được thông qua Ứng Dụng hoặc Các Dịch Vụ theo toàn quyền quyết định của cư dân và tự chịu rủi ro.  Chúng tôi sẽ không chịu trách nhiệm đối với bất kỳ tổn thất hoặc thiệt hại nào mà cư dân hoặc bất kỳ bên thứ ba nào gánh chịu có liên quan đến việc sử dụng thông tin đăng thông qua Ứng Dụng hoặc Các Dịch Vụ.</li>
          <li>(d) Mọi giao dịch, trao đổi thông tin, và tranh chấp nào phát sinh giữa cư dân và những người sử dụng khác hoặc một bên thứ ba có liên quan đến Ứng Dụng, Các Dịch Vụ, và Sản Phẩm sẽ được cư dân tự chịu trách nhiệm xử lý và giải quyết.  Cư dân phải tiết lộ cho chúng tôi tất cả các vấn đề (bao gồm thương lượng bằng miệng và bằng văn bản) mà chúng tôi yêu cầu liên quan đến bất kỳ khiếu nại, yêu cầu hoặc kiện tụng nào giữa cư dân, những người sử dụng khác, hoặc bất kỳ bên thứ ba nào phát sinh từ việc sử dụng Ứng Dụng, Các Dịch Vụ, và Sản Phẩm của cư dân.</li>
          <li>(e) Trong phạm vi tối đa được pháp luật quy định, chúng tôi, cũng như các công ty liên kết của chúng tôi, các bên cấp phép, nhà cung cấp dịch vụ, công ty đối tác, người lao động, đại lý, nhân viên, hoặc giám đốc của chúng tôi hoặc của các công ty liên kết của chúng tôi đều không chịu trách nhiệm đối với thiệt hại theo bất kỳ học thuyết pháp lý nào, phát sinh từ hoặc có liên quan đến việc cư dân sử dụng, hoặc không thể sử dụng, Ứng Dụng, Các Dịch Vụ, và Sản Phẩm (bao gồm cả bất kỳ tai nạn hoặc tổn thương nào khi sử dụng Ứng Dụng, Các Dịch Vụ, và Sản Phẩm) hoặc bất kỳ nội dung nào chứa đựng trong Ứng Dụng, Các Dịch Vụ, và Sản Phẩm hoặc bất kỳ vấn đề nào khác không được chúng tôi bảo đảm hoặc thuộc trách nhiệm của cư dân tại Mục 11 của Phần I, hoặc gây ra bởi bất kỳ đồ vật nào quy định tại Mục 12(ii) của Phần I, hoặc bất kỳ bên thứ ba nào, bao gồm các công ty đối tác của chúng tôi hoặc các nhà thầu.  Trong trường hợp chúng tôi phải chịu trách nhiệm đối với những thiệt hại nhất định, trách nhiệm đó sẽ được giới hạn trong những thiệt hại trực tiếp, thực tế và thông thường (không bao gồm tổn thất về lợi nhuận, thiệt hại gián tiếp, hoặc thiệt hại đặc thù, bất kể thiệt hại đó có thể lường trước được hay không).</li>
        </ul>
      </li>
      <li>
        <b>12. Tạm Ngừng</b> Chúng tôi có thể ngay lập tức tạm ngừng cung cấp Các Dịch Vụ Và Sản Phẩm mà không thông báo trước cho cư dân trong các trường hợp sau.  Chúng tôi sẽ không chịu trách nhiệm đối với bất kỳ tổn thất hoặc thiệt hại nào mà cư dân hoặc bất kỳ bên thứ ba nào gánh chịu do bất kỳ sự chậm trễ hoặc gián đoạn nào trong việc cung cấp Các Dịch Vụ Và Sản Phẩm:
        <ul>
          <li>(i) trong trường hợp bảo trì, kiểm tra, sửa chữa hoặc thay đổi Ứng Dụng hoặc bất kỳ hệ thống nào khác cho Các Dịch Vụ;</li>
          <li>(ii) trong trường hợp không thể cung cấp Các Dịch Vụ Và Sản Phẩm do động đất, núi lửa phun trào, lũ lụt, sóng thần hoặc bất kỳ thảm họa tự nhiên nào khác, hỏa hoạn, mất điện, hoặc bất kỳ rủi ro nhân tạo nào khác, chiến tranh, bạo động, bạo loạn, tranh chấp lao động, quyết định bởi Nhà nước Việt Nam hoặc Chính quyền của Thành phố Hà Nội hoặc lệnh của bất kỳ cơ quan có thẩm quyền nào khác, biện pháp phòng ngừa lây lan dịch bệnh, hoặc bất kỳ nguyên nhân nào khác nằm ngoài sự kiểm soát của chúng tôi;</li>
          <li>(iii) trong trường hợp những người sử dụng khác, các công ty đối tác, hoặc bất kỳ bên thứ ba nào không cung cấp cho chúng tôi các sản phẩm hoặc dịch vụ cần thiết cho việc cung cấp Các Dịch Vụ Và Sản Phẩm cho cư dân; và</li>
          <li>(iv) trong các trường hợp khác mà chúng tôi thấy tạm ngừng Các Dịch Vụ Và Sản Phẩm là cần thiết.</li>
        </ul>
      </li>
      <li>
        <b>13. Chấm Dứt</b>
        <ul>
          <li>
            (a) Nếu bất kỳ sự kiện nào sau đây phát sinh liên quan đến cư dân, hoặc nếu chúng tôi quyết định ngừng cung cấp toàn bộ Các Dịch Vụ tại Việt Nam, chúng tôi có thể, không thông báo trước hoặc yêu cầu, ngừng cung cấp Các Dịch Vụ Và Sản Phẩm và đơn phương chấm dứt Thỏa Thuận này.  Chúng tôi sẽ không chịu trách nhiệm đối với bất kỳ tổn thất hoặc thiệt hại nào mà cư dân hoặc bất kỳ bên thứ ba nào khác phải gánh chịu do chấm dứt việc cung cấp Các Dịch Vụ Và Sản Phẩm:
            <ul>
              <li>(i) bất kỳ sự kiện nào được quy định tại Mục 2.(d) của Phần I;</li>
              <li>(ii) vi phạm Mục 7 của Phần I hoặc bất kỳ quy định nào khác của Các Điều Khoản và Điều Kiện này;</li>
              <li>(iii) bất kỳ sự kiện nào được quy định tại Mục 12 của Phần I kéo dài hơn mười ngày;</li>
              <li>(iv) thừa kế;</li>
              <li>(v) phá sản, mất khả năng thanh toán, hoặc sự kiện tương tự;</li>
              <li>(vi) bắt giữ, giam giữ, hoặc bất kỳ lệnh hoặc hình phạt nào khác từ cơ quan có thẩm quyền; hoặc</li>
              <li>(vii) bất kỳ sự kiện nào khác khiến chúng tôi đánh giá cần thiết chấm dứt cung cấp Các Dịch Vụ Và Sản Phẩm.</li>
            </ul>
          </li>
          <li>
            (b) Cư dân có thể chấm dứt Thỏa Thuận này bằng cách hoàn thành các thủ tục như chúng tôi quy định.
          </li>
          <li>
            (c) Khi Thỏa Thuận này bị chấm dứt, bất kể nguyên nhân nào, cư dân phải ngay lập tức dừng sử dụng Ứng Dụng, Các Dịch Vụ, và Sản Phẩm, và không còn tất cả các quyền theo Các Điều Khoản Và Điều Kiện này.  Nếu cư dân đang sử dụng các đồ vật do chúng tôi cung cấp như một phần trong Các Dịch Vụ, khi chấm dứt, cư dân phải ngay lập tức hoàn trả các đồ vật đó chúng tôi.  Chúng tôi không có nghĩa vụ thực hiện bất kỳ việc gì sau khi chấm dứt, bao gồm cả hoàn trả bất kỳ khoản tiền nào hoặc phản hồi các câu hỏi.  Chúng tôi có thể xóa toàn bộ Dữ Liệu, bao gồm cả đăng ký của cư dân, hoặc tiếp tục lưu giữ và sử dụng Dữ Liệu đó sau khi Chấm Dứt theo toàn quyền quyết định của chúng tôi; tuy nhiên, nếu chúng tôi tiếp tục sử dụng dữ liệu cá nhân cung cấp bởi cư dân, chúng tôi sẽ xử lý Dữ Liệu đó theo pháp luật áp dụng và Chính Sách Bảo Mật của chúng tôi.
          </li>
          <li>(d) Các Mục 3, 8 đến 11, 13, và 16 đến 23 của Phần I, cũng như tất cả các mục khác quy định trách nhiệm, miễn trừ hoặc giới hạn trách nhiệm tại Phần II đến V sẽ duy trì hiệu lực kể cả sau khi Thỏa Thuận này chấm dứt.</li>
        </ul>
      </li>
      <li><b>14. Sửa Đổi</b> Chúng tôi bảo lưu quyền sửa đổi Các Điều Khoản Và Điều Kiện này cấu thành Thỏa Thuận này theo toàn quyền quyết định của chúng tôi với thông báo trước về việc sửa đổi và ngày có hiệu lực.  Bất kỳ thay đổi nào đối với Các Điều Khoản Và Điều Kiện này sẽ có hiệu lực vào “Ngày Cập Nhật Cuối Cùng” được quy định tại cuối Các Điều Khoản Và Điều Kiện này.  Do đó, cư dân phải rà soát Các Điều Khoản Và Điều Kiện này trước khi sử dụng bất kỳ Ứng Dụng hoặc dịch vụ nào trong số Các Dịch Vụ.  Việc tiếp tục sử dụng Ứng Dụng hoặc Các Dịch Vụ của cư dân sau “Ngày Cập Nhật Cuối Cùng” sẽ cấu thành sự chấp nhận và đồng ý của cư dân đối với sửa đổi đó.  Nếu cư dân không chấp nhận sửa đổi đó, cư dân có thể chấm dứt Thỏa Thuận này theo Mục 13(b) của Phần I.</li>
      <li>
        <b>15. Thông Báo</b>
        <ul>
          <li>(a) Bất kỳ sự trao đổi thông tin hoặc thông báo nào từ cư dân đến chúng tôi, bao gồm cả các câu hỏi liên quan đến Các Dịch Vụ, và bất kỳ sự trao đổi thông tin hoặc thông báo nào từ chúng tôi đến cư dân, bao gồm cả thông báo liên quan đến bất kỳ sửa đổi nào đối với Các Điều Khoản Và Điều Kiện này, đều phải được thực hiện theo các thủ tục do chúng tôi quy định.</li>
          <li>(b) Bất kỳ trao đổi thông tin hoặc thông báo nào thực hiện bởi chúng tôi mà được đăng ở một vị trí phù hợp hiển thị trên Ứng Dụng sẽ được xem như là đã được thông báo có hiệu lực đến cư dân và cư dân đã nhận được.</li>
        </ul>
      </li>
      <li><b>16. Chuyển Nhượng</b> Cư dân không được chuyển nhượng bất kỳ quyền nào của cư dân hoặc ủy quyền bất kỳ nghĩa vụ nào của cư dân theo Các Điều Khoản Và Điều Kiện này.  Bất kỳ chuyển nhượng hoặc ủy quyền có chủ đích nào vi phạm quy định này sẽ vô hiệu từ đầu.  Không có chuyển nhượng hoặc ủy quyền nào giải phóng cư dân khỏi bất kỳ nghĩa vụ nào của cư dân theo Các Điều Khoản Và Điều Kiện này.</li>
      <li><b>17. Luật Điều Chỉnh</b> Tất cả các vấn đề phát sinh từ hoặc liên quan đến Các Điều Khoản Và Điều Kiện này hoặc Chính Sách Bảo Mật của chúng tôi được điều chỉnh bởi và giải thích theo pháp luật của nước Cộng Hòa Xã Hội Chủ Nghĩa Việt Nam.</li>
      <li><b>18. Trọng Tài</b> Bất kỳ tranh chấp nào phát sinh từ hoặc có liên quan đến Thỏa Thuận này hoặc Chính Sách Bảo Mật của chúng tôi, bao gồm cả bất kỳ vấn đề nào liên quan đến sự tồn tại, tính hiệu lực hoặc chấm dứt Thỏa Thuận này hoặc Chính Sách Bảo Mật của chúng tôi, sẽ được chuyển đến và giải quyết chung thẩm bằng trọng tài do Trung Tâm Trọng tài Quốc Tế Singapore quản lý theo Quy Tắc Trọng Tài của Trung Tâm Trọng tài Quốc Tế Singapore có hiệu lực tại thời điểm tranh chấp, và quy tắc đó được xem là hợp thành một phần của Thỏa Thuận này bởi dẫn chiếu tại Mục này.  Địa điểm tố tụng trọng tài là Singapore.  Hội đồng trọng tài bao gồm một trọng tài viên.  Ngôn ngữ tố tụng trọng tài là Tiếng Anh.</li>
      <li><b>19. Không Từ Bỏ</b> Việc chúng tôi không thực hiện bất kỳ quyền hoặc quy định nào của Các Điều Khoản Và Điều Kiện này sẽ không cấu thành một sự từ bỏ việc thực hiện các quyền và quy định đó trong tương lai.  Sự từ bỏ các quyền và quy định đó chỉ có hiệu lực khi được lập bằng văn bản và được ký bởi những người đại diện theo ủy quyền hợp pháp của chúng tôi và của cư dân.</li>
      <li><b>20. Không Có Bên Thứ Ba Thụ Hưởng</b> Các Điều Khoản Và Điều Kiện này không, và không có ý định, trao bất kỳ quyền hoặc biện pháp khắc phục nào cho bất kỳ người hoặc chủ thể nào khác ngoài cư dân và chúng tôi.</li>
      <li><b>21. Hiệu Lực Riêng Rẽ</b> Nếu bất kỳ điều khoản nào của Các Điều Khoản Và Điều Kiện này không có hiệu lực, không hợp pháp, vô hiệu, hoặc không thể thực thi, thì các điều khoản đó sẽ xem như bị tách khỏi, và sẽ không ảnh hưởng đến tính hiệu lực hoặc khả năng thực thi của các điều khoản còn lại của, Các Điều Khoản Và Điều Kiện này, tùy trường hợp có thể áp dụng.</li>
      <li><b>22. Toàn Bộ Thỏa Thuận</b> Các Điều Khoản Và Điều Kiện này và Chính Sách Bảo Mật của chúng tôi được xem là thỏa thuận cuối cùng và hợp nhất giữa cư dân và chúng tôi về các vấn đề chứa đựng trong đó.</li>
      <li><b>23. Ngôn Ngữ</b> Các Điều Khoản Và Điều Kiện này và Chính Sách Bảo Mật của chúng tôi được chuẩn bị bằng cả tiếng Anh và tiếng Việt.  Nếu có bất kỳ mâu thuẫn hoặc khác biệt nào giữa bản tiếng Anh và bản tiếng Việt, bản tiếng Anh được ưu tiên áp dụng.</li>
    </ol>
    <h4 class="text-center">
      PHẦN II <br />
      ĐIỀU KHOẢN VÀ ĐIỀU KIỆN CHO DỊCH VỤ CHIA SẺ CUỘC SỐNG
    </h4>
    <ol>
      <li><b>1. Phạm Vi Dịch Vụ</b> Dịch Vụ Chia Sẻ Cuộc Sống là một dịch vụ trung gian (i) chia sẻ các đồ vật và (ii) dạy/được dạy các kỹ năng cho/từ các cư dân khác của Le Grand Jardin tại Không Gian.</li>
      <li>
        <b>2. Cho Mượn Đồ Vật</b>
        <ul>
          <li>
            (a) Các điều kiện sau phải được đáp ứng khi cư dân muốn cho mượn đồ vật:

            <ul>
              <li>
                (i) theo pháp luật và quy định liên quan của Việt Nam, cư dân phải hoàn thành việc xác định danh tính bằng một phương pháp quy định riêng bởi chúng tôi; và
              </li>
              <li>
                (ii) cư dân phải có một địa chỉ tại Le Grand Jardin.
              </li>
            </ul>
          </li>
          <li>(b) Nếu cư dân muốn cho mượn đồ vật qua Ứng Dụng, cư dân phải đăng ký bằng việc cung cấp các thông tin cần thiết (bao gồm hình chụp đồ vật) qua Ứng Dụng và nộp đơn cho chúng tôi để cho mượn đồ vật.</li>
          <li>(c) Chúng tôi sẽ tiến hành đánh giá đơn yêu cầu của cư dân trong vòng bảy ngày kể từ khi yêu cầu quy định tại đoạn liền trước, và thông báo cho cư dân khả năng cho mượn đồ vật liên quan.  Chúng tôi có thể từ chối đơn yêu cầu theo toàn quyền quyết định của chúng tôi.  Trong trường hợp chúng tôi chấp nhận đơn yêu cầu, chúng tôi không bảo đảm rằng chúng tôi sẽ có thể cho những người sử dụng khác mượn đồ vật.  Thời hạn thuê đồ vật sẽ kéo dài trong thời gian của Thời Hạn, bắt đầu từ khi cư dân ký thác đồ vật cho chúng tôi (cư dân không được yêu cầu hoàn trả đồ vật cho đến khi Thời Hạn kết thúc).  Tuy nhiên, chúng tôi có thể rút ngắn thời hạn thuê bằng cách thông báo cho cư dân về việc rút ngắn, nếu cần thiết.</li>
          <li>
            (d) Cư dân theo đây cam đoan và bảo đảm rằng các đồ vật cư dân muốn cho mượn không, và sẽ không, thuộc bất kỳ trường hợp nào sau đây trong suốt Thời Hạn:

            <ul>
              <li>(i) các đồ vật không thuộc về cư dân;</li>
              <li>(ii) các đồ vật được thanh toán bằng thẻ tín dụng hoặc tín dụng mua sắm nhưng chưa hoàn tất thanh toán;</li>
              <li>(iii) các đồ vật bị hư hỏng toàn bộ hoặc một phần hoặc không thể sử dụng một cách bình thường;</li>
              <li>(iv) các đồ vật trộm cắp hoặc các đồ vật khác vi phạm hoặc bị nghi ngờ vi phạm quyền của một bên thứ ba;</li>
              <li>(v) các đồ vật mà bản quyền được nắm giữ bởi một bên thứ ba không phải là cư dân; hoặc</li>
              <li>(vi) các đồ vật bị cấm bán hoặc cho mượn bởi pháp luật.</li>
            </ul>
          </li>
          <li>
            (e) Chúng tôi có thể quy định chi tiết theo tiêu chuẩn của chúng tôi đối với đồ vật được cho mượn bởi những người sử dụng Các Dịch Vụ, chia sẻ chi tiết qua Ứng Dụng, và cho những người sử dụng khác thuê đồ vật như được quy định trong Mục 3 của Phần II.
          </li>
          <li>
            (f) Chúng tôi có thể, không cần sự đồng ý của cá nhân cho mượn các đồ vật, công khai hoặc giữ kín các chi tiết của các đồ vật trên Ứng Dụng; và cư dân không được phép phản đối quyết định của chúng tôi.
          </li>
          <li>
            (g) Chúng tôi sẽ quản lý các đồ vật do cư dân cho mượn với sự cẩn trọng giống như đối với tài sản riêng của chúng tôi để tránh hư hỏng, bị mất, bị trộm, v.v.  Ngoài ra, cư dân đồng ý trước rằng các đồ vật cho mượn có thể bị bẩn hoặc giảm giá trị theo thời gian trong phạm vi sử dụng thông thường và chúng tôi sẽ không chịu trách nhiệm cho việc giảm giá trị đó.
          </li>
          <li>
            (h) Trong thời hạn thuê, bao gồm thời hạn thuê của những người sử dụng khác, nếu các đồ vật bị làm bẩn, hư hỏng, bị mất, bị trộm, bị sử dụng ngoài phạm vi sử dụng thông thường bởi những người sử dụng khác là người sử dụng các đồ vật, do lỗi của chúng tôi hoặc của những người sử dụng khác, chúng tôi sẽ, tùy theo lựa chọn của chúng tôi, bồi thường cho cư dân các thiệt hại xảy ra hoặc cung cấp các đồ vật thay thế.  Khoản tiền bồi thường sẽ được giới hạn tối đa là US$150, và người sử dụng sẽ chấp nhận trước mức giới hạn tối đa trước khi cho mượn đồ vật.
          </li>
        </ul>
      </li>
      <li>
        <b>3. Thuê các đồ vật</b>
        <ul>
          <li>(a) Cư dân có thể đăng ký với chúng tôi để thuê các đồ vật sau khi xác định các đồ vật cư dân muốn thuê qua Ứng Dụng.  Việc thuê các đồ vật sẽ bắt đầu khi cư dân nhận được đồ vật trong khu vực được chuẩn bị để trưng bày các đồ vật cho thuê trong Không Gian (“<b>Khu Vực Trưng Bày</b>”). </li>
          <li>
            (b) Thời hạn thuê các đồ vật là ba ngày từ khi cư dân nhận được đồ vật trong Khu Vực Trưng Bày; tuy nhiên, chúng tôi có thể rút ngắn thời hạn thuê bằng việc thông báo cho cư dân, nếu cần thiết.
          </li>
          <li>(c) Nếu các đồ vật thuê bị hư hỏng, bẩn hoặc khác đi so với các đồ vật khi cư dân nhận, cư dân không được sử dụng các đồ vật đó và phải thông báo cho chúng tôi ngay lập tức.  Nếu cư dân không thông báo cho chúng tôi khi nhận các đồ vật này, các đồ vật sẽ được coi là bị hư hỏng, bong tróc hoặc bị mất do sơ suất của cư dân.</li>
          <li>(d) Cư dân phải quản lý các đồ vật thuê một cách hết sức cẩn thận và, trừ khi có chấp thuận trước của chúng tôi, không được cho bất kỳ người nào khác ngoài cư dân, người đã yêu cầu thuê, sử dụng các đồ vật thuê.  Cư dân phải chịu trách nhiệm đối với bất kỳ hư hỏng, bong tróc, bị mất hoặc bị trộm nào của các đồ vật thuê do cư dân hoặc bất kỳ bên nào khác gây ra bất kể có phải do lỗi của cư dân hay không.</li>
          <li>
            (e) Cư dân không được thực hiện bất kỳ hành vi nào sau đây liên quan đến việc sử dụng các đồ vật thuê:

            <ul>
              <li>(i) cho thuê lại hoặc cho phép một bên thứ ba sử dụng các đồ vật;</li>
              <li>(ii) chuyển nhượng, cầm cố hoặc dùng làm tài sản bảo đảm theo cách khác các đồ vật thuê;</li>
              <li>(iii) bất kỳ hành vi vi phạm pháp luật nào;</li>
              <li>
                (iv) sử dụng các đồ vật thuê cho bất kỳ mục đích nào ngoài việc sử dụng ban đầu của các đồ vật đó;
              </li>
              <li>(v) cố ý làm hư hỏng, bong tróc, vứt bỏ hoặc các hành vi tương tự;</li>
              <li>(vi) hoàn trả hoặc có ý định hoàn trả cho chúng tôi bất kỳ đồ vật nào khác ngoài các đồ vật thuê; hoặc</li>
              <li>(vii) bất kỳ hành vi nào khác mà chúng tôi cho rằng tương tự các mục ở trên.</li>
            </ul>
          </li>
          <li>(f) Cư dân phải hoàn trả các đồ vật thuê về Khu Vực Trưng Bày và thông báo cho chúng tôi chậm nhất khi thời hạn thuê kết thúc.  Nếu bất kỳ đồ vật nào khác ngoài các đồ vật thuê được tìm thấy trong Khu Vực Trưng Bày vào thời điểm hoàn trả, chúng tôi bảo lưu quyền xử lý các đồ vật đó mà không phải bồi thường cho cư dân.</li>
          <li>(g) Cư dân đồng ý rằng các đồ vật thuê có thể bao gồm các đồ vật được những người dùng khác ủy thác cho chúng tôi hoặc cho chúng tôi mượn.</li>
          <li>
            (h) Trong trường hợp hư hỏng, bong tróc, bị mất hoặc bị trộm các đồ vật trước khi cư dân hoàn trả các đồ vật này, cư dân phải thông báo việc đó cho chúng tôi ngay lập tức.  Trong trường hợp này, bất kể nguyên nhân hoặc cho dù nguyên nhân đó có do lỗi của cư dân hay không, cư dân phải thanh toán cho chúng tôi khoản tiền được quy định cho từng đồ vật sau với từng lý do sau:
            <ul>
              <li>(i) trong trường hợp hư hỏng hoặc bong tróc có thể sửa chữa được: một khoản tiền tương đương với chi phí sửa chữa hợp lý mà người sửa chữa do chúng tôi chỉ định và bất kỳ tổn thất nào mà chúng tôi gánh chịu trong thời gian sửa chữa; và</li>
              <li>(ii) trong trường hợp hư hỏng hoặc bong tróc không thể sửa chữa, hoặc khi bị mất hoặc bị trộm, một khoản tiền tương đương với giá trị của các đồ vật mà không thể hoàn trả.  Chúng tôi có thể xem giá trị của đồ vật là tương đương với chi phí để mua đồ vật thay thế hoặc khoản tiền bồi thường mà chúng tôi phải trả cho chủ sở hữu của đồ vật đó.  Chúng tôi có quyền xác định một cách hợp lý rằng có phải hư hỏng hoặc bong tróc là không sửa chữa được hay không; và nếu cư dân không hoàn trả các đồ vật thuê trong vòng mười ngày sau khi thời hạn thuê kết thúc, chúng tôi có thể xem như là các đồ vật đã bị mất và áp dụng quy định (ii) này.</li>
            </ul>
          </li>
          <li>
            (i) Nội dung đoạn liền trước không loại trừ quyền chúng tôi yêu cầu cư dân bồi thường các thiệt hại khác ngoài thiệt hại đối với các đồ vật.
          </li>
        </ul>
      </li>
      <li>
        <b>4. Dạy Các Kỹ Năng Cho Những Người Khác
        </b>
        <ul>
          <li>
            (a) Nếu cư dân giảng dạy các bài học cho những người sử dụng khác trong dịch vụ dạy kỹ năng, các điều kiện sau phải được đáp ứng:
            <ul>
              <li>(i) theo pháp luật và quy định liên quan của Việt Nam, cư dân phải hoàn thành việc xác định danh tính bằng một phương pháp quy định riêng bởi chúng tôi; và</li>
              <li>
                (ii) cư dân phải có một địa chỉ tại Le Grand Jardin.
              </li>
            </ul>
          </li>
          <li>
            (b) Nếu cư dân muốn giảng dạy các bài học trong dịch vụ dạy kỹ năng, cư dân phải đăng ký thông tin cần thiết (bao gồm mô tả về bài học) qua Ứng Dụng và nộp đơn yêu cầu cho chúng tôi để thực hiện các bài học dựa trên các kỹ năng của cư dân.
          </li>
          <li>(c) Chúng tôi sẽ tiến hành đánh giá đơn yêu cầu của cư dân trong vòng bảy ngày kể từ khi có đơn yêu cầu quy định tại đoạn liền trước, và thông báo cho cư dân khả năng giảng dạy các bài học tại khu vực mà chúng tôi chia sẻ.  Chúng tôi có thể từ chối đơn yêu cầu theo toàn quyền quyết định của chúng tôi.  Trong trường hợp chúng tôi chấp nhận đơn yêu cầu, chúng tôi không bảo đảm rằng chúng tôi sẽ có thể chiêu mộ người tham gia bài học.</li>
          <li>
            (d) Cư dân không được giảng dạy các bài học có thể thuộc một trong các mục dưới đây:

            <ul>
              <li>(i) các bài học vi phạm hoặc khuyến khích việc vi phạm quyền sở hữu trí tuệ, bản quyền, v.v;</li>
              <li>(ii) các bài học vi phạm pháp luật;</li>
              <li>(iii) các bài học gây mất trật tự công cộng và trái với đạo đức;</li>
              <li>
                (iv) các bài học yêu cầu hoặc khuyến khích người tham gia trực tiếp hoặc gián tiếp chịu bất kỳ gánh nặng tài chính nào;
              </li>
              <li>(v) các bài học sử dụng tài liệu có bản quyền của bên thứ ba không phải người sử dụng;</li>
              <li>(vi) các bài học yêu cầu giấy phép hoặc các kỹ năng đặc biệt để thực hiện; và</li>
              <li>(vii) các bài học được xác định là có rủi ro gây thương tích cho người sử dụng khác hoặc người tham gia;</li>
            </ul>
          </li>
          <li>
            (e) Chúng tôi có thể mời những người sử dụng khác tham gia vào các bài học qua Ứng Dụng, theo tiêu chuẩn của chúng tôi về giới thiệu bài học.  Chúng tôi sẽ không có bất kỳ trách nhiệm nào đối với nội dung của các bài học trong bất kỳ trường hợp nào, bao gồm cả các trường hợp mà các bài học bị cấm theo mục (d) ở trên được giảng dạy bởi cư dân.
          </li>
          <li>
            (f) Chúng tôi có thể, không cần sự đồng ý của cư dân, công khai hoặc giữ kín chi tiết của các bài học trên Ứng Dụng, và cư dân không được phép phản đối quyết định của chúng tôi.
          </li>
          <li>(g) Chúng tôi sẽ không chịu trách nhiệm đối với bất kỳ thiệt hại nào gây ra cho những người sử dụng hoặc các bên thứ ba liên quan đến việc thực hiện, tham gia vào hoặc hủy bỏ các bài học.  Ngoài ra, nếu có tranh chấp giữa cư dân và một bên thứ ba (bao gồm cả những người sử dụng khác) liên quan đến việc thực hiện, tham gia vào, hoặc hủy bỏ bài học, cư dân sẽ tự chịu trách nhiệm giải quyết tranh chấp đó bằng chi phí riêng của cư dân, và không được gây thiệt hại cho chúng tôi.</li>
          <li>
            (h) Chúng tôi không tham gia vào việc chuẩn bị và phương pháp sử dụng dịch vụ dạy kỹ năng này bởi cư dân.
          </li>
        </ul>
      </li><li>
        <b>5. Học Kỹ Năng do Những Người Khác dạy</b>
        <ul>
          <li>(a) Cư dân có thể đăng ký với chúng tôi để tham gia các bài học được giảng dạy bởi những người sử dụng khác sau khi xác định các bài học mà cư dân muốn tham gia qua Ứng Dụng.  Nếu bài học mà cư dân đăng ký vẫn còn chỗ trống, cư dân sẽ được đăng ký là người tham gia bài học và được phép tham gia lớp học.</li>
          <li>
            (b) Cư dân có thể hủy đăng ký tham gia bài học bằng một phương pháp quy định riêng bởi chúng tôi. Chúng tôi có thể hủy bài học theo toàn quyền quyết định của chúng tôi mà không chịu bất kỳ trách nhiệm nào nếu cần thiết.
          </li>
          <li>(c) Khi cư dân tham gia các bài học, cư dân phải tuân theo các hướng dẫn đưa ra bởi chúng tôi hoặc người sử dụng giảng dạy bài học đó.</li>
          <li>(d) Chúng tôi sẽ không chịu bất kỳ trách nhiệm nào đối với việc giảng dạy hoặc nội dung của các bài học trong bất kỳ trường hợp nào, bao gồm cả các trường hợp mà các bài học bị cấm theo Mục 4.(d) của Phần II được giảng dạy bởi những người sử dụng khác.</li>
          <li>(e) Chúng tôi sẽ không chịu bất kỳ trách nhiệm nào đối với bất kỳ thiệt hại nào gây ra cho những người sử dụng hoặc các bên thứ ba liên quan đến việc thực hiện, tham gia vào hoặc hủy các bài học. Ngoài ra, nếu có tranh chấp giữa cư dân và một bên thứ ba (bao gồm cả những người sử dụng khác) liên quan đến việc thực hiện, tham gia vào hoặc hủy một bài học, cư dân sẽ tự chịu trách nhiệm giải quyết tranh chấp đó bằng chi phí riêng của cư dân, và không được gây thiệt hại cho chúng tôi.</li>
        </ul>
      </li>
    </ol>
    <h4 class="text-center">
      PHẦN III <br />
      ĐIỀU KHOẢN VÀ ĐIỀU KIỆN CHO DỊCH VỤ KIỂM TRA SỨC KHỎE THÔNG MINH
    </h4>
    <ol>
      <li>
        <b>1. Phạm Vi Dịch Vụ</b> Trong Dịch Vụ Kiểm Tra Sức Khỏe THÔNG MINH, thiết bị để đo chiều cao, cân nặng, tỷ lệ phần trăm mỡ trong cơ thể, BMI, khối lượng cơ bắp, huyết áp, v.v, được cung cấp trong Không Gian cho phép các cư dân đăng ký của Le Grand Jardin đo các số đo cơ thể và được một y tá tư vấn, nếu cần thiết.  Những người sử dụng có thể kiểm tra dữ liệu đo được và sự thay đổi các dữ liệu đó theo thời gian trên Ứng Dụng.
      </li>
      <li>
        <b>2. Đề phòng cho những người sử dụng về các thiết bị đo lường</b>
        <ul>
          <li>
            (a) Chúng tôi và các y tá sử dụng thiết bị để đo các số đo đều sẽ không bảo đảm tính chính xác cũng như các yếu tố khác của các kết quả đo.  Chúng tôi không chịu bất kỳ trách nhiệm nào với cư dân hoặc với bất kỳ bên thứ ba nào liên quan đến các kết quả đo của cư dân.
          </li>
          <li>(b) Chúng tôi và các y tá không chịu trách nhiệm đối với bất kỳ thiệt hại nào gây ra cho cư dân hoặc bất kỳ bên thứ ba nào không phải do lỗi của chúng tôi hoặc các y tá của chúng tôi.</li>
          <li>
            (c) Nếu cư dân chưa đủ 20 tuổi, cư dân phải được bố mẹ hoặc người giám hộ đi theo hoặc đồng ý để sử dụng dịch vụ.
          </li>
        </ul>
      </li>
      <li>
        <b>3. Tư vấn từ một y tá</b>
        <ul>
          <li>(a) Bất kỳ tư vấn nào được đưa ra bởi một y tá để phản hồi kết quả đo lường là dựa trên giá trị ngưỡng được quy định bởi chúng tôi hoặc công ty đối tác của chúng tôi, MEDRiNG Co., Ltd. ("<b>Medring</b>"), và không phải là một tư vấn y tế.  Chúng tôi và Medring sẽ không chịu trách nhiệm đối với bất kỳ tổn thất hoặc thiệt hại nào phát sinh liên quan đến tư vấn kể cả trường hợp tư vấn đó gây hiểu lầm, sai, hoặc không chính xác ở khía cạnh khác.</li>
          <li>(b) Nếu cư dân đang được điều trị hoặc hướng dẫn y tế do mang thai, bệnh hoặc các lý do khác, cư dân phải thông báo trước với y tá và có thể không được phép sử dụng dịch vụ này</li>
        </ul>
      </li>
      <li>
        <b>4. Dữ liệu đo được tích lũy</b> Cư dân đồng ý rằng dữ liệu được đo theo Mục 1 của Phần III sẽ được lưu giữ trong Ứng Dụng hoặc máy chủ của chúng tôi và được sử dụng cho Các Dịch Vụ.  Chúng tôi sẽ quản lý dữ liệu cá nhân của cư dân trong kết quả đo sức khỏe tổng hợp tuân theo Chính Sách Bảo Mật.
      </li>
    </ol>
    <h4 class="text-center">
      PHẦN IV <br />
      ĐIỀU KHOẢN VÀ ĐIỀU KIỆN CHO DỊCH VỤ SÂN CHƠI THÔNG MINH
    </h4>
    <ol>
      <li><b>1. Phạm Vi Dịch Vụ</b> Trong Dịch Vụ Sân Chơi Thông Minh, một không gian vui chơi (“<b>Sân Chơi Thông Minh</b>”) được cung cấp cho trẻ em trong Không Gian, và được trang bị các thiết bị vui chơi và máy quay phim để các thành viên trong gia đình có thể theo dõi tình hình khi trẻ em đang vui chơi.</li>
      <li>
        <b>2. Phạm vi sử dụng</b>
        <ul>
          <li>(a) Những người sử dụng Sân Chơi Thông Minh (cụ thể là trẻ em) phải ít nhất đủ năm tuổi và chưa đủ mười tuổi.  Một người sử dụng Sân Chơi Thông Minh phải có bố mẹ hoặc người giám hộ của người sử dụng đi theo.  Người sử dụng phải là người phụ thuộc của người giám hộ, hoặc người tương đương của họ, và người giám hộ phải ít nhất đủ 20 tuổi, theo Các Điều Khoản Và Điều Kiện, đảm bảo rằng người sử dụng tuân thủ Các Điều Khoản Và Điều Kiện, và giám sát người sử dụng trực tiếp hoặc gián tiếp bằng máy quay phim được trang bị tại Sân Chơi Thông Minh. </li>
          <li>
            (b) Những người sử dụng phải theo hướng dẫn của nhân viên khi sử dụng Sân Chơi Thông Minh.  Việc sử dụng Sân Chơi Thông Minh của người sử dụng có thể bị hạn chế nếu người sử dụng không theo hướng dẫn của nhân viên mặc dù một vài yêu cầu.  Nhân viên sẽ hỗ trợ hoạt động vui chơi của người sử dụng chỉ bằng việc gợi ý làm sao để vui chơi trong Sân Chơi Thông Minh; người giám hộ phải chịu trách nhiệm hoàn toàn việc đảm bảo an toàn của người sử dụng tại mọi thời điểm khi người sử dụng sử dụng Sân Chơi Thông Minh.
          </li>
          <li>(c) Không được mang thức ăn và đồ uống vào Sân Chơi Thông Minh.  Vui lòng ăn hoặc uống tại các khu vực được chỉ định xung quanh.</li>
        </ul>
      </li>
      <li><b>3. Đề phòng sử dụng</b>  Chúng tôi không chịu trách nhiệm đối với bất kỳ tai nạn hoặc thương tích nào đối với những người sử dụng, các vấn đề giữa những người sử dụng, hoặc thiệt hại do bất kỳ tai nạn hoặc thương tích nào có thể xảy ra bên trong Sân Chơi Thông Minh.</li>
    </ol>
    <h4 class="text-center">
      PHẦN V <br />
      ĐIỀU KIỆN VÀ ĐIỀU KHOẢN CHO DỊCH VỤ CÀ PHÊ TỐT CHO SỨC KHỎE
    </h4>
    <ol>
      <li><b>1. Phạm Vi Dịch Vụ</b> Dịch vụ cà phê được phục vụ bởi Hotel du Park trong Không Gian.  Ngoài cà phê và thức ăn nhẹ, quán cà phê này cung cấp một thực đơn có lợi cho sức khỏe, cho phép bạn trải nghiệm các thực đơn tốt cho sức khỏe khác nhau.  Chỉ những người sử dụng đăng ký thông tin cần thiết qua Ứng Dụng mới có thể vào quán cà phê, và chúng tôi sẽ không chịu trách nhiệm đối với bất kỳ thức ăn và đồ uống nào được phục vụ bởi quán cà phê.</li>
    </ol>
    <div class="note">
      <b>Ngày Thi Hành Phiên Bản Đầu Tiên (Ngày Cập Nhật Cuối Cùng): <i>Ngày 27 tháng 02 năm 2022.</i></b>
    </div>

    <h3 class="text-center text-uppercase my-4">
      Chính Sách Bảo Mật
    </h3>
    <p>
      SUMITOMO CORPORATION (sau đây gọi là “<b>chúng tôi</b>” hoặc “<b>của chúng tôi</b>” tùy theo ngữ cảnh yêu cầu) quy định chính sách bảo mật này (“<b>Chính Sách Bảo Mật</b>” này) để công bố và thông báo cho cư dân cách mà chúng tôi thu thập và xử lý dữ liệu cá nhân của cư dân liên quan đến việc cung cấp Các Dịch Vụ được xác định trong Các Điều Khoản Và Điều Kiện.  Vui lòng đọc Chính Sách Bảo Mật này trước khi cư dân sử dụng Các Dịch Vụ.  Các cụm từ được định nghĩa trong Các Điều Khoản Và Điều Kiện có cùng ý nghĩa như trong Chính Sách Bảo Mật này.  Bằng việc cung cấp thông tin cho chúng tôi, đăng ký và tạo tài khoản thông qua Ứng Dụng hoặc sử dụng Các Dịch Vụ, cư dân theo đây thừa nhận rằng cư dân đã đọc Chính Sách Bảo Mật này và cư dân đồng ý vô điều kiện và không hủy ngang đối với việc chúng tôi thu thập, sử dụng, lưu trữ, quản lý và xử lý Dữ Liệu Cá Nhân của cư dân (như được định nghĩa dưới đây) theo Chính Sách Bảo Mật này.  Nếu cư dân muốn yêu cầu chúng tôi ngừng sử dụng Dữ Liệu Cá Nhân của cư dân, cư dân theo đây đồng ý liên hệ với chúng tôi theo hướng dẫn tại Mục 6 dưới đây.  Nếu cư dân không đồng ý với Chính Sách Bảo Mật này, vui lòng không đăng ký hoặc sử dụng Các Dịch Vụ.
    </p>
    <p>Chúng tôi cam kết bảo vệ thông tin mà chúng tôi nhận được từ cư dân và đã thực hiện các biện pháp kỹ thuật và tổ chức hợp lý để đạt được mục tiêu này.   Tuy nhiên, cư dân thừa nhận rằng không có việc truyền tải dữ liệu nào qua Internet là hoàn toàn an toàn, và cư dân tự chịu rủi ro khi truyền tải những thông tin đó cho chúng tôi.</p>
    <ol>
      <li>
        <b>1. Chúng tôi thu thập và xử lý dữ liệu cá nhân của cư dân như thế nào?</b>
        <p><b>(1) Dữ Liệu Cá Nhân được thu thập</b> </p>
        <p>
          Chúng tôi có thể thu thập các loại dữ liệu sau đây.  Dữ liệu chúng tôi thu thập có thể cho phép nhận dạng các cá nhân cụ thể và thuộc phạm vi dữ liệu cá nhân theo pháp luật áp dụng (“<b>Dữ Liệu Cá Nhân</b>”):
        </p>
        <ul>
          <li>(i) Thông tin và thông tin tài khoản có được dưới dạng điện tử tại thời điểm đăng ký của người sử dụng.</li>
          <li>(ii) Thông tin (cụ thể tên, ngày sinh, địa chỉ, số điện thoại, địa chỉ e-mail, giới tính, tuổi, hình ảnh khuôn mặt để xác thực, kết quả kiểm tra sức khỏe (bao gồm dữ liệu có thể đo lường) và số giấy tờ chứng thực cá nhân cho phép chúng tôi xác định danh tính cư dân) có được khi sử dụng Ứng Dụng hoặc Các Dịch Vụ, và diện mạo, hình dáng và chuyển động của người sử dụng trong khu vực.</li>
          <li>
            (iii) Thông tin (bao gồm các tên, các địa chỉ, các số điện thoại, các địa chỉ e-mail, các ngày sinh, giới tính, tuổi, các câu trả lời cho bảng câu hỏi, v.v.) có được từ những người sử dụng tham gia bảng câu hỏi, v.v.  Tên, địa chỉ, số điện thoại, địa chỉ e-mail, ngày sinh, giới tính và tuổi của cư dân sẽ được thu thập bằng nhiều loại bảng câu hỏi, v.v., và chúng tôi sẽ cho cư dân biết trước về sự cần thiết của việc thu thập thông tin của cư dân qua bảng câu hỏi, v.v.
          </li>
          <li>(iv) Thông tin có được liên quan đến việc những người sử dụng sử dụng Ứng Dụng hoặc Các Dịch Vụ, ví dụ như các nội dung (các hình ảnh, các bài viết, các bình luận, v.v.), trạng thái sử dụng dịch vụ của những người sử dụng, hành vi tiêu dùng, và xu hướng hoạt động của người sử dụng.</li>
          <li>(v) Hình ảnh và thông tin khác được thu thập bởi hệ thống nhận dạng khuôn mặt ghi lại video của những người sử dụng Các Dịch Vụ trong khu vực nơi Các Dịch Vụ được cung cấp để đảm bảo rằng Các Dịch Vụ được cung cấp một cách an toàn.</li>
          <li>(vi) Các loại thông tin khác và các câu hỏi của người sử dụng cho chúng tôi.</li>
        </ul>
        <p><b>(2) Phương Thức Thu Thập</b></p>
        <p>Chúng tôi có thể thu thập Dữ Liệu Cá Nhân trực tiếp từ cư dân, bằng lời nói hoặc bằng văn bản, khi cư dân đăng ký sử dụng Các Dịch Vụ, và sau đó thông qua việc cư dân sử dụng Các Dịch Vụ.</p>
        <p><b>(3) Mục Đích và Phạm Vi Sử Dụng</b></p>
        <p>
          Chúng tôi có thể sử dụng Dữ Liệu Cá Nhân của cư dân cho các Mục Đích sau (“<b>Mục Đích</b>”):
        </p>
        <ul>
          <li>
            (i) Cung cấp Các Dịch Vụ và quản lý Các Dịch Vụ sau khi cư dân đăng ký.
          </li>
          <li>(ii) Đảm bảo sự an toàn của Không Gian nơi Các Dịch Vụ được cung cấp và quản lý việc kiểm kê các đồ vật có sẵn để cho thuê.</li>
          <li>(iii) Xác nhận và trao đổi các vấn đề cần thiết liên quan đến Ứng Dụng hoặc Các Dịch Vụ, và để phản hồi các yêu cầu.</li>
          <li>(iv) Cung cấp thông tin khuyến mại về các sản phẩm, dịch vụ, và các ưu đãi đặc biệt của chúng tôi và các công ty đối tác của chúng tôi, và cho các hoạt động tiếp thị khác.</li>
          <li>(v) Xác thực và cải thiện các thuật toán phân tích một số rủi ro đối với sức khỏe của cư dân và tính toán số điểm rủi ro dựa trên việc phân tích, và việc đó được sử dụng để phát triển các sản phẩm dịch vụ bảo hiểm. </li>
          <li>(vi) Chuẩn bị dữ liệu thống kê dưới dạng mà không xác định hoặc chỉ rõ các cá nhân, và phân tích tiếp thị bằng cách sử dụng dữ liệu đó, nhằm mục đích tham khảo trong các hoạt động bán hàng và phát triển các dịch vụ mới.</li>
          <li>(vii) Sử dụng thông tin kết hợp với thông tin do chúng tôi nắm giữ riêng.</li>
          <li>(viii) Thực hiện các quyền và các quyền hợp pháp khác theo Các Điều Khoản Và Điều Kiện.</li>
        </ul>

        <p class="note">
          Bất kể những quy định trên, nếu chúng tôi ẩn danh Dữ Liệu Cá Nhân một cách thích hợp, chúng tôi có thể sử dụng dữ liệu ẩn danh cho bất kỳ mục đích nào.
        </p>
      </li>
      <li>
        <b>2. Ai có thể truy cập dữ liệu cá nhân của cư dân?</b>
        <p>
          Nhân viên của chúng tôi có thể truy cập Dữ Liệu Cá Nhân.  Ngoài ra, để cung cấp Các Dịch Vụ, chúng tôi có thể cần tiết lộ Dữ Liệu Cá Nhân của cư dân cho các bên khác vì một hoặc nhiều Mục Đích trong các trường hợp sau:
        </p>
        <ul>
          <li>(i) Khi chúng tôi thuê nhà thầu bên ngoài cho việc thu thập, lưu trữ, hoặc xử lý Dữ Liệu Cá Nhân hoặc việc cung cấp Các Dịch Vụ cho, hoặc chia sẻ Dữ Liệu Cá Nhân cho các Mục Đích với, các công ty đối tác của chúng tôi như các công ty chăm sóc sức khỏe hoặc các công ty bảo hiểm, các nhà thầu như các bên cung cấp công nghệ thông tin, hoặc bất kỳ bên thứ ba nào (bao gồm nhưng không giới hạn, Hakuhodo Inc., Medring, NEC Corporation, Công Ty Cổ Phần Truyền Thông Square, Công Ty TNHH Quảng Cáo Trực Tiếp Bình Phương, Công Ty TNHH Bảo Hiểm Phi Nhân Thọ MSIG Việt Nam, Mitsui Sumitomo Insurance Company, Limited và ReMark International B.V.);</li>
          <li>(ii) Khi chúng tôi chuyển giao hoặc tiết lộ Dữ Liệu Cá Nhân cho công ty mẹ của chúng tôi hoặc bất kỳ công ty liên kết nào khác (bao gồm các công ty khác trong nhóm công ty của chúng tôi; “<b>Nhóm Công Ty</b>”).</li>
          <li>(iii) Khi chúng tôi được phép chuyển giao hoặc tiết lộ Dữ Liệu Cá Nhân cho một bên thứ ba không cần có sự đồng ý của cư dân theo bất kỳ luật hoặc quy định nào (bất kể trong nước hoặc nước ngoài) áp dụng đối với chúng tôi hoặc bất kỳ Nhóm Công Ty nào khác.</li>
          <li>(iv) Khi chúng tôi tin rằng cư dân  đã hành động theo cách gây bất lợi cho những người sử dụng khác hoặc các bên thứ ba, chúng tôi có thể thông báo cho các bên thứ ba đó, cảnh sát hoặc các cơ quan có thẩm quyền liên quan về việc đăng ký Các Dịch Vụ của cư dân.</li>
          <li>
            (v) Khi chúng tôi cần tiết lộ Dữ Liệu Cá Nhân cho một bên mua hoặc bên mua tiềm năng trong trường hợp sáp nhập hoặc mua lại, bao gồm cả việc chuẩn bị cho việc đó.
          </li>
        </ul>
        <p>
          Bất kể những quy định trên, nếu chúng tôi ẩn danh Dữ Liệu Cá Nhân một cách thích hợp, chúng tôi có thể tiết lộ dữ liệu ẩn danh cho bất kỳ bên thứ ba nào.  Ví dụ, dữ liệu sau có thể được tiết lộ sau khi ẩn danh;
        </p>
        <ul>
          <li>
            (i) Thông tin thuộc tính của tất cả những người sử dụng Các Dịch Vụ (thông tin tổng hợp tuổi, giới tính, và nơi cư trú của những người sử dụng).
          </li>
          <li>
            (ii) Nội dung do cư dân cung cấp cho Công Ty.
          </li>
          <li>(iii) Thông tin định lượng phản hồi của những người sử dụng đối với các tin nhắn do Công Ty gửi (số lượng những người sử dụng đã tiếp cận, số lần hiển thị, số lần bấm nút, v.v.)</li>
          <li>
            (iv) Kết quả phản hồi bảng câu hỏi, v.v.
          </li>
        </ul>
      </li>
      <li>
        <b>3. Chúng tôi lưu trữ và xử lý dữ liệu cá nhân của cư dân ở đâu?</b>
        <p>
          Chúng tôi có thể lưu trữ và xử lý Dữ Liệu Cá Nhân tại các văn phòng hoặc trung tâm dữ liệu của Nhóm Công Ty hoặc các nhà cung cấp bên thứ ba, ví dụ như ở Việt Nam, Nhật Bản, Singapore hoặc bất kỳ quốc gia nào khác
        </p>
      </li>
      <li>
        <b>4. Chúng tôi lưu trữ và xử lý dữ liệu cá nhân của bạn trong bao lâu?</b>
        <p>
          Trong phạm vi tối đa được pháp luật cho phép, chúng tôi có thể lưu trữ và xử lý Dữ Liệu Cá Nhân cho đến khi không còn cần thiết cho bất kỳ Mục Đích nào (chúng tôi cũng có thể tiếp tục lưu trữ và xử lý Dữ Liệu Cá Nhân sau khi Thỏa thuận, dựa trên Các Điều Khoản Và Điều Kiện đã bị chấm dứt, miễn là Dữ Liệu Cá Nhân vẫn cần thiết cho bất kỳ Mục Đích nào).  Bất kể những quy định trên, chúng tôi có thể lưu trữ và xử lý dữ liệu ẩn danh mà không có bất kỳ giới hạn nào.
        </p>
      </li>
      <li>
        <b>5. Địa chỉ của người kiểm soát là người thu thập, quản lý, và xử lý dữ liệu cá nhân của cư dân là gì?</b>
        <p>
          Chúng tôi, SUMITOMO CORPORATION, chịu trách nhiệm thu thập, quản lý, và xử lý Dữ Liệu Cá Nhân, và địa chỉ của chúng tôi như sau:
        </p>
        <p>
          OTEMACHI PLACE EAST TOWER, 3-2 Otemachi 2-Chome, Chiyoda-ku, Tokyo 100-8601, Nhật Bản
        </p>
      </li>
      <li>
        <b>6. Cư dân nên làm gì khi khiếu nại về dữ liệu cá nhân của cư dân, hoặc truy cập, sửa đổi, và/hoặc xóa dữ liệu cá nhân của cư dân?
        </b>
        <p>
          Nếu cư dân muốn gửi khiếu nại liên quan đến Dữ Liệu Cá Nhân của cư dân hoặc muốn truy cập, sửa đổi, xóa và/hoặc yêu cầu chúng tôi ngừng xử lý Dữ Liệu Cá Nhân của cư dân, vui lòng liên hệ với chúng tôi như quy định tại mục (i) bên dưới.  Khi nhận được khiếu nại hoặc yêu cầu của cư dân, chúng tôi sẽ giải quyết hoặc phản hồi theo cách sau:
        </p>
        <ul>
          <li><span class="text-underline">(i)  Bước 1:</span>Cư dân có thể gửi khiếu nại hoặc yêu cầu của cư dân cho chúng tôi theo một trong những cách sau:</li>
          <li>Email: <a href="mailto:smartcityschn@gmail.com">smartcityschn@gmail.com</a></li>
          <li>
            <span class="text-underline">(ii) Bước 2:</span> Khi nhận được khiếu nại hoặc yêu cầu của cư dân, người đại diện dịch vụ khách hàng sẽ xác minh danh tính của cư dân và, nếu cần, yêu cầu cư dân cung cấp cho chúng tôi thông tin hoặc tài liệu để làm rõ nội dung khiếu nại hoặc yêu cầu của cư dân.  Sau khi hoàn tất quá trình này, người đại diện dịch vụ khách hàng sẽ chuyển khiếu nại hoặc yêu cầu của cư dân đến người phụ trách có liên quan.
          </li>
          <li><span class="text-underline">(iii) Bước 3:</span> Người phụ trách có liên quan sẽ liên hệ với cư dân qua điện thoại hoặc e-mail để giải quyết hoặc phản hồi khiếu nại hoặc yêu cầu của cư dân, hoặc báo cáo cho cư dân về việc khiếu nại hoặc yêu cầu của cư dân đã được giải quyết hoặc hoàn thành.  Nếu chúng tôi không thể giải quyết hoặc phản hồi khiếu nại hoặc yêu cầu của cư dân vì một lý do chính đáng (ví dụ: khi yêu cầu của cư dân không đáp ứng các yêu cầu được quy định trong pháp luật áp dụng), người phụ trách có liên quan sẽ giải thích lý do đó cho cư dân.</li>
        </ul>
      </li>
      <li>
        <b>7. Sửa đổi Chính Sách Bảo Mật này</b>
        <p>
          Chúng tôi có thể sửa đổi Chính Sách Bảo Mật này tùy từng thời điểm theo Phần I, Mục 14 của Các Điều Khoản Và Điều Kiện.
        </p>
      </li>
    </ol>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
#tac ol {
padding-left: 0;
}
ol > li > ul, ol, ul {
  list-style-type: none ;
  list-style-position: outside;
  &.none {
    list-style-type: lower-roman;
  }
  > ul {
    list-style-type: lower-roman;
  }
}
li {
  margin: 3vw 0;
}
.text-underline {
  text-decoration: underline;
}
</style>
