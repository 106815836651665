import axios from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    waitList: [],
    isLoading: true,
    isLoadingCheckIn: false,
    isMore: true,
    examinationData: [],
    listHealthInfo: [],
    isLoadingListHealthInfo: false,
    listExamData: [],
    examinationItemData: {},
  },
  getters: {
    getWaitList(state) {
      return state.waitList
    },
    getLoadingWait(state) {
      return state.isLoading
    },
    getLoadingCheckIn(state) {
      return state.isLoadingCheckIn
    },
    getIsMore(state) {
      return state.isMore
    },
    getExaminationData(state) {
      return state.examinationData
    },
    getListHealthInfo(state) {
      return state.listHealthInfo
    },
    getIsLoadingListHealthInfo(state) {
      return state.isLoadingListHealthInfo
    },
    getExaminationItemData(state) {
      return state.examinationItemData
    },
    getListExamData(state) {
      return state.listExamData
    },
  },
  mutations: {
    setWaitList(state, value) {
      state.waitList = value
    },
    setLoading(state, value) {
      state.isLoading = value
    },
    setLoadingCheckIn(state, value) {
      state.isLoadingCheckIn = value
    },
    setIsMore(state, value) {
      state.isMore = value
    },
    setExaminationData(state, value) {
      state.examinationData = value
    },
    setListHealthInfo(state, value) {
      state.listHealthInfo = value
    },
    setIsLoadingListHealthInfo(state, value) {
      state.isLoadingListHealthInfo = value
    },
    setListExamData(state, value) {
      state.listExamData = value
    },
    setExaminationItemData(state, value) {
      state.examinationItemData = value
    },
  },
  actions: {
    fetchWaitList({ commit }, params) {
      commit('setLoading', true)

      return axios
        .get('/v1/infirmary/checkIn', {
          params,
        })
        .then(resp => {
          const { result, data } = resp.data

          if (result === 'success') {
            commit('setWaitList', data)
          }
        })
        .catch(error => {
          console.log(error)

          // reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    loadMoreWaitList({ commit, state }, params) {
      commit('setLoading', true)

      return axios
        .get('/v1/infirmary/checkIn', {
          params,
        })
        .then(resp => {
          const { result, data } = resp.data
          if (result === 'success') {
            commit('setWaitList', [...state.waitList, ...data])
          }

          if (!data.length) {
            commit('setIsMore', false)
          }
        })
        .catch(error => {
          console.log(error)

          // reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    checkIn({ commit }, qrCode) {
      commit('setLoadingCheckIn', true)

      return new Promise((resolve, reject) => {
        axios
          .post('/v1/infirmary/checkIn', {
            qr_code: qrCode,
          })
          .then(resp => {
            console.log(resp)
            resolve(resp)
          })
          .catch(error => {
            console.log(error)

            reject(error)
          })
          .finally(() => {
            commit('setLoadingCheckIn', false)
          })
      })
    },

    fetchExaminationById({ commit }, id) {
      commit('setLoading', true)

      return axios
        .get(`/v1/infirmary/examination/${id}`)
        .then(resp => {
          const { result, data } = resp.data
          console.log(data)
          if (result === 'success') {
            commit('setExaminationItemData', data)
          }
        })
        .catch(error => {
          console.log(error)

          // reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },

    fetchExaminationInfo({ commit }, params) {
      commit('setLoading', true)

      return axios
        .get('/v1/infirmary/examination', {
          params,
        })
        .then(resp => {
          const { result, data } = resp.data

          if (result === 'success') {
            commit('setExaminationData', data)
          }
        })
        .catch(error => {
          console.log(error)

          // reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    saveExamination(ctx, bodyData) {
      const { id } = bodyData
      // eslint-disable-next-line no-param-reassign
      delete bodyData.id

      return axios.post(`/v1/infirmary/examination/${id}`, bodyData)
    },
    removeExamination(ctx, id) {
      return axios.delete(`/v1/infirmary/checkIn/${id}`)
    },
    saveExaminationById(ctx, bodyData) {
      const { id } = bodyData
      // eslint-disable-next-line no-param-reassign
      delete bodyData.id

      return axios.put(`/v1/infirmary/examination/${id}`, bodyData)
    },
    removeExaminationById(ctx, id) {
      return axios.delete(`/v1/infirmary/examination/${id}`)
    },
    waitExaminationById(ctx, id) {
      return axios.post(`/v1/infirmary/checkIn/wait/${id}`)
    },
    fetchListExamination({ commit }, params) {
      commit('setIsLoadingListHealthInfo', true)

      return axios
        .get('/v1/infirmary/examination/users', {
          params,
        })
        .then(resp => {
          const { result, data } = resp.data

          if (result === 'success') {
            commit('setListHealthInfo', data)
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          commit('setIsLoadingListHealthInfo', false)
        })
    },
    fetchExamList({ commit }, params) {
      commit('setLoading', true)

      return axios
        .get('/v1/infirmary/nurse/examination', {
          params,
        })
        .then(resp => {
          const { result, data } = resp.data

          if (result === 'success') {
            commit('setListExamData', data)
          }
        })
        .catch(error => {
          console.log(error)

          // reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    loadMoreExamList({ commit, state }, params) {
      commit('setLoading', true)

      return axios
        .get('/v1/infirmary/nurse/examination', {
          params,
        })
        .then(resp => {
          const { result, data } = resp.data
          if (result === 'success') {
            commit('setListExamData', [...state.listExamData, ...data])
          }

          if (!data.length) {
            commit('setIsMore', false)
          }
        })
        .catch(error => {
          console.log(error)

          // reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
  },
}
