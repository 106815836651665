<template>
  <component
    :is="resolveLayoutVariant"
    v-if="loaded"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>
    <Snackbar />
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import Snackbar from '@/components/Snackbar.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import store from '@/store'
import Bus from '@/utils/bus'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'

// Layouts
import useDynamicVh from '@core/utils/useDynamicVh'
import { computed } from '@vue/composition-api'

// Dynamic vh

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    Snackbar,
  },
  setup() {
    const loaded = computed(() => !!store.getters['auth/currentUser'] || !store.getters['auth/isLoggedIn'])
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()
    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    if (store.getters['auth/isLoggedIn']) {
      store.dispatch('auth/getUserInfo')
      store.dispatch('auth/getRoles')
    }

    return {
      loaded,
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
  mounted() {
    const channel = this.$pusher.subscribe(process.env.VUE_APP_NOTIFY_CHANNEL)
    channel.bind('notification', msg => this.handleNotification(msg))
    setTimeout(() => {
      if (!this.$vuetify.breakpoint.smAndDown && this.$router.currentRoute.query.nomobile !== '1') {
        this.$router.replace('/error-mobile')
      }
    }, 500)
  },

  methods: {
    handleNotification(msg) {
      setTimeout(() => {
        const currentUser = this.$store.getters['auth/currentUser']

        console.log(msg)

        if (msg.code_event === 'play_ground_002') {
          // eslint-disable-next-line camelcase
          const { id_user, family_name, members } = msg.data
          if (members.includes(currentUser.id_user) || currentUser.permission.manage_playground) {
            this.$store.commit('snackbar/show', {
              message: msg.data.family_id > 0 ? this.$t('PlaygroundNoti', { id_user, family_name }) : this.$t('PlaygroundNotiNoGroup', { id_user }),
              type: msg.type_message,
              action: msg.code_event,
            })
          }
        }

        if (msg.code_event === 'town_infirmary_001' && currentUser.permission.manage_town) {
          this.showMsg(msg)
        }

        if (msg.id_user === 0 && msg.code_event !== 'play_ground_001') {
          this.showMsg(msg)
        }

        // Normal message
        if (msg.id_user === currentUser.id_user && msg.code_event !== 'play_ground_001') {
          this.showMsg(msg)
        }
        Bus.$emit('haveMessage', msg.code_event)
      }, 2000)
    },
    showMsg(msg) {
      this.$store.commit('snackbar/show', {
        message: msg.code_event,
        type: msg.type_message,
        action: msg.code_event,
      })
    },
  },
}
</script>
