<template>
  <v-img
    :src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
    :alt="$i18n.locale"
    height="14px"
    width="22px"
    @click="isEnglish = !isEnglish"
  ></v-img>
</template>

<script>
import { getVuetify } from '@core/utils'
import { ref, watch } from '@vue/composition-api'
import { loadLanguageAsync } from '@/plugins/i18n'
import store from '@/store'

export default {
  setup() {
    const $vuetify = getVuetify()

    const isEnglish = ref(store.state.app.language)

    watch(isEnglish, val => {
      const language = val ? 'en' : 'vi'
      $vuetify.lang.current = language
      loadLanguageAsync(language)
      store.commit('app/CHANGE_LANGUAGE', language)
    })

    return {
      isEnglish,
    }
  },
}
</script>
