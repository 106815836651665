import _ from 'lodash'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    messages: [],
  },
  getters: {
    getMessage(state) {
      return state.messages.length > 0 ? state.messages[0] : null
    },
  },
  mutations: {
    show(state, message) {
      if (message.message) state.messages.push({ ...message, id: Date.now() })
    },
    hide(state, message) {
      const { messages } = state
      _.remove(messages, m => m.id === message.id)
      Vue.set(state, 'messages', [...messages])
    },
  },
}
