export const ITEM_STATUS = {
  AVAILABLE: 1,
  BORROWED: 2,
  FAIL: 3,
  RETURN: 4,
}

export const ACTION_SCAN_QR = {
  BORROW: 'borrow',
  CREATE: 'create',
  RETURN: 'return',
}

const shareBoxHelpers = {
  ITEM_STATUS,
}

export default shareBoxHelpers
