/* eslint-disable import/no-cycle */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/lib/services/goto'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,

  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',

  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (to.name === from.name) {
      return
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    // eslint-disable-next-line consistent-return
    return goTo(scrollTo)
  },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some(route => route.meta.authRequired || route.meta.permissions)
  const { permission } = routeTo.matched[0].meta
  const currentUser = store.getters['auth/currentUser']

  if (authRequired && !store.getters['auth/isLoggedIn']) {
    // Save path to localStorage for later use
    store.commit('auth/setAuthRedirect', routeTo.fullPath)

    return next('/login')
  }
  if (permission) {
    if (!currentUser || !currentUser.permission[permission]) {
      return next('/')
    }
  }

  // Redirect if logged in
  if (routeTo.meta.redirectIfLoggedIn && store.getters['auth/isLoggedIn']) {
    next('/home')
  }

  // Final call
  return next()
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // TODO: Finish loading
              }

              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }

    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

router.afterEach(() => {
  // TODO: Finish loading
})

Vue.router = router

export default router
