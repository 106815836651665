import {
  mdiAccountOutline,
  mdiHeartPulse,
  mdiHomeOutline,
  mdiLibrary,
  mdiMonitorShare,
  mdiSeesaw,
  mdiTicketPercent,
} from '@mdi/js'

export default [
  {
    title: 'Home',
    icon: mdiHomeOutline,
    to: 'home',
  },
  {
    title: 'List Coupon',
    icon: mdiTicketPercent,
    to: 'cafe',
  },
  {
    title: 'Town Infirmary',
    icon: mdiHeartPulse,
    to: 'town-infirmary-user',
  },
  {
    title: 'Share Box',
    icon: mdiMonitorShare,
    to: 'sharebox',
  },
  {
    title: 'SkillShare',
    icon: mdiLibrary,
    to: 'skill-share-list',
  },
  {
    title: 'Playground',
    icon: mdiSeesaw,
    to: 'playground',
  },
  { subheader: 'Staff' },
  {
    title: 'Users Management',
    icon: mdiAccountOutline,
    to: 'staff-users-management',
    permission: 'manage_user',
  },
  {
    title: 'Coupon Management',
    icon: mdiTicketPercent,
    to: 'staff-cafe',
    permission: 'manage_cafe',
  },
  {
    title: 'Coupon Export',
    icon: mdiTicketPercent,
    to: 'staff-cafe-export',
    permission: 'manage_coupon',
  },
  {
    title: 'Nurse',
    icon: mdiHeartPulse,
    permission: 'manage_town',
    children: [
      { title: 'Examination', to: 'staff-town-infirmary' },
      {
        title: 'List Examination',
        to: 'staff-town-exam-list',
      },
    ],
  },
  {
    title: 'Share Box Management',
    icon: mdiMonitorShare,
    to: 'staff-sharebox',
    permission: 'manage_share_box',
  },
  {
    title: 'Skill Share Management',
    icon: mdiLibrary,
    to: 'staff-skill-share-list',
    permission: 'manage_skill_share',
  },
]
