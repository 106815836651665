<template>
  <div id="tac">
    <h3 class="text-center mb-4">
      TERMS AND CONDITIONS
    </h3>
    <h4 class="text-center">
      PART I <br />
      GENERAL TERMS AND CONDITIONS FOR THE SERVICES
    </h4>
    <ol class="pl-0 mt-4">
      <li>
        <b>1. General Application</b>
        <ul>
          <li>(a) By registering for Smart Life Station Smart Services ("<b>Services</b>"), which content is defined and detailed in Part II and thereafter, and creating an account through the application specially designed for the Services ("<b>Application</b>"), you confirm that you are of legal age and have full civil capacity to enter into this agreement ("<b>Agreement</b>") with SUMITOMO CORPORATION (referred to as "<b>us</b>", "<b>we</b>", "<b>our</b>" or "<b>Company</b>" as the context may require), lawfully resident in Vietnam, and you accept and are bound by the terms and conditions set forth in this Part I and the terms and conditions applying to either of the Services set forth in Part II and thereafter (collectively, “<b>Terms and Conditions</b>”).  If you do not meet all of these requirements, you shall not access or use the Application or Services.</li>
          <li>(b) You shall not use the Application or Services if (i) you do not agree to these Terms and Conditions, (ii) you do not satisfy the eligibility requirements as prescribed in item (a) above, or (iii) you are prohibited by law from using any of the Application or Services.</li>
          <li>
            (c)These Terms and Conditions must be read in conjunction with our Privacy Policy set forth below before using the Services and registering your account via the Application.
          </li>
        </ul>
      </li>
      <li>
        <b>2. Registration</b>
        <ul>
          <li>(a) If you wish to use the Services, you may create your own account via the Application and register for use of the Services by agreeing to comply herewith and providing certain information as specified by the Company, in accordance with the manner prescribed by the Company.</li>
          <li>(b) Upon completion of the creation and registration pursuant to the preceding paragraph, this Agreement shall be executed under these Terms and Conditions between you and us, allowing you to use the Application and Services pursuant to the Terms and Conditions.</li>
          <li>(c) You shall promptly notify us of any change to the information that you provided upon registration in the manner prescribed by us, otherwise the registered information shall be deemed the latest and accurate.</li>
          <li>
            (d) You cannot create your own account and register for use of the Services, and we can refuse your creation of an account and/or terminate this Agreement pursuant to Section 13.(a) in Part I, without any obligation to disclose the reasons, if:
            <ul class="none">
              <li>(i) you do not live in Le Grand Jardin;</li>
              <li>(ii) all or any part of the information provided by you to us upon the creation of account and registration is found to be false, inaccurate or omitted;</li>
              <li>(iii) you are a minor, adult ward or person under curatorship or assistance, and applicable approval or consent has not been obtained from your legal representative, guardian, curator or assistant;</li>
              <li>(iv) you are determined by us to (i) constitute an organized crime group or a member thereof, right-wing organization, anti-social force or other similar person or entity (collectively, "<b>Anti-social Force</b>"), or (ii) have any interaction or involvement with an Anti-social Force in any manner such as assisting or being involved in the maintenance, operation or management of an Anti-social Force by financing or other means;</li>
              <li>(v) you are determined by us to be a party having violated any agreement with us, or to have been involved with such violating party; or</li>
              <li>(vi) in addition to the foregoing, we deem the registration inappropriate.</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <b>3. Password and User ID Management</b>
        <ul>
          <li>
            (a) You shall be responsible for keeping and maintaining your password and user ID for the Application and Services in an appropriate manner and shall not cause a third party to use, or provide, transfer, change the name, or sell or otherwise dispose of, the same.
          </li>
          <li>(b) You shall be, and we shall not be, liable for any damages arising out of inappropriate management, misuse, or use by a third party of your password or user ID.  Any use of the Application or Services by any third party with your password and user ID shall be deemed your use.</li>
        </ul>
      </li>
      <li><b>4. License</b> Upon the registration above, we hereby grant you a non-exclusive, non-transferrable, non-sublicensable and revocable software license to download onto and use the Application on your own device during the term of this Agreement (<b>“Term”</b>) only for the purpose of using the Services subject to these Terms and Conditions in Vietnam.</li>
      <li>
        <b>5. Services</b> Upon the registration above, you have the right to enter the space for the Services (<b>“Space“</b>) prepared by us in Le Grand Jardin with the Application and use the Services during the Term.  Currently, the Services are provided free of charge, except where you purchase food or drink in the Space, and you may use the Services only for non-commercial, personal use, at your own risk and expense.  The scope and regulations of the Services are set forth in Parts II to V of these Terms and Conditions.
      </li>
      <li>
        <b>6. Term</b> The Services will be provided as a service demonstration trial by us only to the residents of Le Grand Jardin who have registered and created an account through the Application for a limited period from February 13, 2022 to May 6, 2022, which is subject to change at our discretion.  The Term shall commence on the date of your registration and automatically end when the period of the demonstration trial ends.
      </li>
      <li>
        <b>7. Prohibited Actions</b> When using the Services, you shall not conduct or cause a third party to conduct any act that falls under, or is determined by us to fall under, any of the following:
        <ul>
          <li>
            (i) violation of applicable laws or infringement of others’ rights.  This includes the transmission of any information or data that falls under, or is determined by us to fall under, information or data that:
            <ul>
              <li>- contains excessively violent or cruel content;</li>
              <li>- contains computer viruses or other hazardous computer programs;</li>
              <li>- contains content that damages the reputation or the credit of us, other users of the Services or other third parties;</li>
              <li>- contains excessively indecent content;</li>
              <li>- contains content that encourages discrimination;</li>
              <li>- contains content that encourages suicide or self-mutilation;</li>
              <li>- contains content that encourages drug abuse;</li>
              <li>- contains antisocial content;</li>
              <li>- is intended to be distributed to third parties, including chain mails; or</li>
              <li>- contains content that causes third parties to feel uncomfortable.</li>
            </ul>
          </li>
          <li>(ii) infringement of our or any third party’s intellectual property rights (e.g., copyrights, patents, trademarks, utility model rights, design rights), including any copy, translation, change, distribution or upload of the Application;</li>

          <li>(iii) composing, disclosing, exhibiting, transmitting, providing or uploading content that contains computer viruses or any other software, programs, files or computer code intended to interfere with or destroy the functionality of computer software, hardware or telecommunications equipment;</li>
          <li>(iv) transmit or post false information;</li>
          <li>(v) any act of commercial advertising or any other commercial purpose not in accordance with these Terms and Conditions.  This includes the diversion, sale, use, rental, reproduction or adaptation of all or part of the Services or Application, decompiling of the Application or any other attempt for reverse engineering;</li>
          <li>(vi) conducting surveys, collecting information without the consent of the other party, contests, pyramid schemes, chain letters, etc.;</li>
          <li>
            (vii) interfering with other users’ use of the Services or interfering with our operation of the Services.  This includes interfering with or disrupting the servers or networks that deliver the Services;
          </li>
          <li>(viii) unauthorized use of other user's information;</li>
          <li>(ix) any action that harms minors;</li>
          <li>(x) impersonating a third party;</li>
          <li>(xi) acts that are unethical, vulgar, harmful, indecent, inappropriate or disgusting to others including other users; or</li>
          <li>(xii) entering the Space with pets or any other animals, damaging or staining any facility or goods in the Space, or any other act contrary to our instructions separately notified or posted via the Application or in the Space.</li>
        </ul>
      </li>
      <li><b>8. Intellectual Property Rights</b> Any copyrights, patents, utility model rights, design rights, trademark rights and other trade mark rights and other intellectual property rights (including rights to obtain, or apply for registration of such rights) related to the Application and the Services are expressly reserved by us or our licensors</li>
      <li><b>9. Confidentiality</b> You shall keep confidential any non-public information disclosed in relation to the Services by us to you regarding which we require your confidential treatment upon such disclosure, unless you have obtained our prior written approval.</li>
      <li><b>10. Data</b> We may process or disclose to third parties all data provided by you or your device through the Services or Application (“<b>Data</b>”) for our own business at our sole discretion; however, we shall process or disclose your personal data pursuant to applicable laws and our Privacy Policy, which is separately prescribed.  You hereby agree to our processing of your personal data pursuant to our Privacy Policy.</li>
      <li>
        <b>11. Disclaimer and Limitation of Liability</b>
        <ul class="style_abc">
          <li>(a) We do not make any warranties, express or implied, (i) that the Services, Application, and other products or services provided or rented in relation to the Services, including the facial recognition system which records video of users of the Services in the area where the Services are provided in order to authenticate your identity and ensure that the Services are provided in a safe manner, (collectively, "<b>Services and Products</b>") fit or are suitable for a particular purpose contemplated by you, (ii) that the Services and Products have expected functions, commercial value, accuracy or usefulness (including but not limited to authentication accuracy), (iii) that the use by you of the Services and Products complies with laws and regulations, (iv) that the Services and Products will be free of interruption, bugs, defects or infringement of intellectual property rights held by third parties, or (v) the Data will not be leaked, or data breach or any other cyber security incident will not occur in the system for the Services and Products.</li>
          <li>(b) If you wish to use the services of our partner companies and other companies that provide information on or services in relation to the Application or Services, you do so at your own risk, and access and use the websites of our partner companies and other companies in accordance with the terms and conditions set forth on those sites.  We have no control over other sites linked to the Application or Services and are not responsible for any changes or updates to such sites or any termination of their services.  We will not be liable for any loss or damage incurred by you or any third party in connection with your use of any other site linked to the Application or Services.  In addition, we do not guarantee the completeness, accuracy, certainty, usefulness, or any other aspect of the products, services, or information provided by our partner companies.</li>
          <li>(c) You may use information obtained via the Application or Services at your own risk and discretion.  We shall not be liable for any loss or damage incurred by you or any third party in connection with the use of the information posted via the Application or Services.</li>
          <li>(d) Any transactions, communications, and disputes arising between you and other users or a third party in connection with the Application, Services, and Products shall be addressed and resolved by you at your responsibility.  You shall disclose to us all matters (including written and oral negotiations) that we request in relation to any complaint, claim or litigation between you, other users, or any third party arising out of your use of the Application, Services, and Products.</li>
          <li>(e) To the fullest extent provided by law, neither we, nor our affiliates, nor our or our affiliates’ licensors, service providers, partner companies, employees, agents, officers, or directors are liable for damage under any legal theory, arising out of or in connection with your use of, or inability to use, the Application, Services, and Products (including any accident or injury in using them) or any content obtained therein or any other matter unwarranted by us or at your responsibility in this Section 11 of Part I or caused by any of the items set forth in Section 12(ii) of Part I, or any third party, including our partner companies or contractors.  In the event that we are found liable for certain damages, such liability shall be limited to direct, actual, and ordinary damages (excluding any loss of profit, indirect damages, or special damages, regardless of whether such damage was foreseeable).</li>
        </ul>
      </li>
      <li>
        <b>12. Suspension</b> We may immediately suspend provision of the Services and Products without prior notice to you in the following circumstances.  We shall not be liable for any loss or damage incurred by you or any other third party due to any delay or interruption in the provision of the Services and Products:
        <ul>
          <li>(i) in the event of maintenance, inspection, repair or change of the Application or any other system for the Services;</li>
          <li>(ii) in the event that the Services and Products cannot be provided due to an earthquake, eruption, flood, tsunami or any other natural disaster, fire, power failure, or any other man-made accident, war, disturbance, riot, labor dispute, a decision by the State of Vietnam or Hanoi City Government or any other authority’s order, preventative measures against the spread of diseases, or any other cause that is outside our control;</li>
          <li>(iii) in the event that other users, partner companies, or any third parties do not provide us with necessary products or services for the provision of the Services and Products to you; and</li>
          <li>(iv) in any other cases where we deem it necessary to temporarily suspend the Services and Products.</li>
        </ul>
      </li>
      <li>
        <b>13. Termination</b>
        <ul>
          <li>
            (a) If any of the following events arises in relation to you, or if we decide to cease providing the Services in their entirety in Vietnam, we may, without prior notice or demand, suspend provision of the Services and Products and terminate this Agreement unilaterally.  We shall not be liable for any loss or damage incurred by you or any other third party due to termination of the provision of the Services and Products:
            <ul>
              <li>(i) any event set forth in Section 2.(d) of Part I;</li>
              <li>(ii) breach of Section 7 of Part I or any other provision of these Terms and Conditions;</li>
              <li>(iii) any event set forth in Section 12 of Part I lasting for more than ten days;</li>
              <li>(iv) inheritance;</li>
              <li>(v) bankruptcy, insolvency, or similar events;</li>
              <li>(vi) arrest, detention, or any other order or penalty from an authority; or</li>
              <li>(vii) any other event under which we deem it necessary to terminate the provision of the Services and Products.</li>
            </ul>
          </li>
          <li>
            (b) You may terminate this Agreement by completing the procedures prescribed by us.
          </li>
          <li>(c) Once this Agreement is terminated, regardless of the cause, you shall immediately stop using the Application, Services, and Products, and lose all the rights under these Terms and Conditions.  If you are using items provided by us as one of the Services upon the termination, you shall immediately return them to us.  We are not obliged to do anything after the termination, including any refund or response to questions.  We may delete all the Data, including your registration, or continue to store and use it after the Termination at our own discretion; however, if we continue to use personal data provided by you, we shall process it pursuant to the applicable laws and our Privacy Policy.</li>
          <li>(d) Sections 3, 8 to 11, 13, and 16 to 23 of Part I, as well as all the sections providing liabilities, disclaimers or limitation of liability in Part II to V shall survive even after this Agreement is terminated.</li>
        </ul>
      </li>
      <li><b>14. Amendment</b> We reserve the right to amend these Terms and Conditions constituting this Agreement at our sole discretion with prior notice of the amendment and effective date.  Any changes to these Terms and Conditions will be in effect as of the “Date of Last Update” set forth at the end of these Terms and Conditions.  As such, you shall review these Terms and Conditions before using any of the Application or Services.  Your continued use of the Application or Services after the “Date of Last Update” will constitute your acceptance of and agreement to such amendment.  If you do not accept such amendment, you may terminate this Agreement in accordance with Section 13(b) of Part I.</li>
      <li>
        <b>15. Notice</b>
        <ul>
          <li>(a) Any communications or notices from you to us, including inquiries with respect to the Services, and any communications or notices from us to you, including notices concerning any amendment to the Terms and Conditions shall be made in accordance with the procedures specified by us.</li>
          <li>(b) Any communication or notice made by us that is posted to an appropriate place displayed on the Application shall be deemed to be effectively notified to and received by you.</li>
        </ul>
      </li>
      <li><b>16. Assignment</b> You shall not assign any of your rights or delegate any of your obligations under these Terms and Conditions.  Any purported assignment or delegation in violation of this provision is void ab initio.  No assignment or delegation relieves you of any of your obligations under these Terms and Conditions.</li>
      <li><b>17. Governing law</b> All matters arising out of or relating to these Terms and Conditions or our Privacy Policy are governed by and construed in accordance with the laws of the Socialist Republic of Vietnam.</li>
      <li><b>18. Arbitration</b> Any dispute arising out of or in connection with this Agreement or our Privacy Policy, including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration administered by the Singapore International Arbitration Centre in accordance with the Arbitration Rules of the Singapore International Arbitration Centre for the time being in force, which rules are deemed to be incorporated by reference in this Section.  The seat of arbitration shall be Singapore.  The Tribunal shall consist of one arbitrator.  The language of the arbitration shall be English.</li>
      <li><b>19. No Waivers</b> Our failure to enforce any right or provision of these Terms and Conditions will not constitute a waiver of future enforcement of that right or provision.  The waiver of any right or provision will be effective only if in writing and signed by our and your duly authorized representatives.</li>
      <li><b>20. No Third-Party Beneficiaries</b> These Terms and Conditions do not, and are not intended to, confer any rights or remedies upon any person or entity other than you and us.</li>
      <li><b>21. Severability</b> If any provision of these Terms and Conditions is invalid, illegal, void, or unenforceable, then that provision will be deemed severed from, and will not affect the validity or enforceability of the remaining provisions of, these Terms and Conditions, as applicable.</li>
      <li><b>22. Entire Agreement</b> These Terms and Conditions and our Privacy Policy are deemed to be the final and integrated agreement between you and us on the matters contained therein.</li>
      <li><b>23. Language</b> These Terms and Conditions and our Privacy Policy are prepared in both English and Vietnamese.  If there is any contradiction or discrepancy between them, the English version shall prevail.</li>
    </ol>
    <h4 class="text-center">
      PART II <br />
      TERMS AND CONDITIONS FOR LIFE SHARING SERVICE
    </h4>
    <ol>
      <li><b>1. Scope of Service</b> The Life Sharing Service is a service for intermediaries of (i) sharing items and (ii) teaching/being taught skills to/from other residents of Le Grand Jardin at the Space.</li>
      <li>
        <b>2. Lending Items</b>
        <ul>
          <li>
            (a) The following conditions must be fulfilled when you wish to lend items:
            <ul>
              <li>
                (i) in accordance with the relevant laws and regulations in Vietnam, you shall have completed identification by a method separately specified by us; and
              </li>
              <li>
                (ii) you have an address in Le Grand Jardin.
              </li>
            </ul>
          </li>
          <li>(b) If you wish to lend items through the Application, you shall register by providing the necessary information (including a photograph of the items) through the Application and apply to us to lend the items.</li>
          <li>(c) We will carry out an assessment of your application within seven days from the application set out in the preceding paragraph, and notify you of the possibility of lending the item concerned.  We may reject the application at our sole discretion.  In the event that we accept the application, we do not guarantee that we will be able to lend the item to other users.  The rental term of the item shall last for the duration of the Term, which begins from when you deposit the item with us (you may not request that the item be returned until the end of the Term).  However, we may shorten the rental term by providing you with notification thereof, as necessary.</li>
          <li>
            (d) You hereby represent and warrant that the items you wish to lend do not and will not fall under any of the following during the Term:
            <ul>
              <li>(i) items that do not belong to you;</li>
              <li>(ii) items for which the credit card or shopping credit payment has not been completed;</li>
              <li>(iii) items that are broken in whole or in part or that cannot be used normally;</li>
              <li>(iv) stolen items or other items that infringe or are suspected of infringing the rights of a third party;</li>
              <li>(v) items whose copyright is held by a party other than you; or</li>
              <li>(vi) items that are prohibited by law from being sold or lent.</li>
            </ul>
          </li>
          <li>
            (e) We may set the details according to our standards on items lent by the users of the Services, share the details through the Application, and rent the items to other users as stated in Section 3 of Part II.
          </li>
          <li>(f) We may, without the consent of the individual who has lent the items, make details of the items public or private on the Application; and you are not permitted to object to our decision.</li>
          <li>(g) We shall manage the items lent by you with the same duty of care as our own property to prevent damage, loss, theft, etc.  In addition, you agree in advance that the lent items may become dirty or deteriorate over time within the scope of normal use and we shall not be liable for such deterioration.</li>
          <li>
            (h) During the rental term, including the rental terms of other users, if the items are soiled, damaged, lost, stolen, used beyond the scope of normal use by other users who use the items, due to our or the other users’ fault, we shall, at our option, compensate you for the damages caused or provide replacement items.  The amount of compensation shall be limited to an upper limit of US$150, and the user shall accept the upper limit in advance before lending the items.
          </li>
        </ul>
      </li>
      <li>
        <b>3. Renting of items</b>
        <ul>
          <li>(a) You may apply to us for rental of items after identifying the items you wish to rent through the Application.  The rental of items shall start when you receive the items in the area prepared to display rental items in the Space (“<b>Display Area</b>”).</li>
          <li>
            (b) The rental term of items shall be three days from when you receive the items in the Display Area; however, we may shorten the rental term by notifying you, as necessary.
          </li>
          <li>(c) If the rented items are damaged, stained or different from when you received them, you shall not use them and shall notify us immediately.  If you do not notify us upon receipt of the items, they shall be deemed to be damaged, defaced or lost due to your negligence.</li>
          <li>(d) You shall manage the rented items with a high duty of care and, except with our prior consent, the rented items shall not be used by anyone other than you who applied for the rental.  You shall be responsible for any damage, defacement, loss or theft of the rented items caused by you or any other party regardless of whether the cause is your fault.</li>
          <li>
            (e) You shall not perform any of the following acts in relation to use of the rented items:

            <ul>
              <li>(i) subletting or allowing a third party to use the items;</li>
              <li>(ii) assign, pledge or otherwise encumber the rented items;</li>
              <li>(iii) any illegal act;</li>
              <li>(iv) using the rented items for any purpose other than their original use;</li>
              <li>(v) intentional damage, defacement, disposal or similar acts;</li>
              <li>(vi) return or attempt to return to us any items other than the rented items; or</li>
              <li>(vii) any other act that we deem to be similar to the preceding items.</li>
            </ul>
          </li>
          <li>(f) You shall return the rented items to the Display Area and notify us by the end of the rental term.  If any items other than the rented ones are found in the Display Area at the time of return, we reserve the right to dispose of such items without compensation to you.</li>
          <li>(g) You shall agree that the rented items may include items entrusted or lent to us by other users.</li>
          <li>
            (h) In the event of damage, defacement, loss or theft of the items until you return them, you shall notify us immediately thereof.  In this case, regardless of the cause or whether it is attributable to you, you shall pay us the amount specified for each of the following items for each of the following reasons:
            <ul>
              <li>(i) in the event of repairable damage or defacement: An amount equivalent to the cost of reasonable repair by a repairman designated by us and any loss incurred by us during the period of repair; and</li>
              <li>(ii) in the event of irreparable damage or defacement, or loss or theft, an amount equivalent to the value of the items that cannot be returned.  We may deem that the value of the items is equivalent to the cost for procuring alternative ones or the amount of damages paid to the items owner by us.  We may reasonably determine whether the damage or defacement is irreparable; and if you do not return the rented items within ten days after the rental term ends, we may deem the items lost and have this provision (ii) apply.</li>
            </ul>
          </li>
          <li>
            (i) The preceding paragraph shall not preclude us from claiming compensation from you for damage other than to the items.
          </li>
        </ul>
      </li>
      <li>
        <b>4. Teaching Skills to Other Persons</b>
        <ul>
          <li>
            (a) If you provide lessons to other users in the skill teaching service, the following conditions must be met:
            <ul>
              <li>(i) In accordance with the relevant laws and regulations in Vietnam, you shall have completed identification by a method separately specified by us; and</li>
              <li>
                (ii) you have an address at Le Grand Jardin.
              </li>
            </ul>
          </li>
          <li>(b) If you wish to to provide lessons inthe skill teaching service, you may register the necessary information (including a description of the lesson) via the Application and apply to us to conduct lessons based on your skills.</li>
          <li>(c) We will carry out an assessment of your application within seven days from the application set out in the preceding paragraph, and notify you of the possibility of providing lessons in the area we share.  We may reject the application at our sole discretion.  In the event that we accept the application, we do not guarantee that we will be able to recruit participants for the lesson.</li>
          <li>
            (d) You shall not provide lessons that might fall under any of the following items:

            <ul>
              <li>(i) lessons that infringe or encourage infringement of intellectual property rights, copyrights, etc.;</li>
              <li>(ii) lessons that are against the law;</li>
              <li>(iii) lessons that are offensive to public order and morals;</li>
              <li>(iv) lessons that require or encourage the participant to bear any financial burden directly or indirectly;</li>
              <li>(v) lessons that use copyrighted material of the third party other than the user;</li>
              <li>(vi) lessons that require a license or special skills to administer; and</li>
              <li>(vii) lessons that are determined to have a risk of injuring another user or participant; ticipant;</li>
            </ul>
          </li>
          <li>(e) We may invite other users to participate in the lessons via the Application, according to our standards on lesson introduction.  We shall not take any responsibility for the contents of lessons in any case, including cases where lessons prohibited under item (d) above are offered by you. </li>
          <li>
            (f) We may, without your consent, make details of the lessons public or private on the Application; and you shall not be able to express any objection to our decision.
          </li>
          <li>(g) We shall not be responsible for any damage caused to users or third parties in relation to the implementation of, participation in or cancellation of lessons.  In addition, if there is a dispute between you and a third party (including other users) in relation to the implementation of, participation in, or cancellation of a lesson, you shall resolve such dispute at your own responsibility and expense, and shall not cause any damage to us.</li>
          <li>(h) We are not involved in the preparation and method of use of this skill teaching service by you.</li>
        </ul>
      </li><li>
        <b>5. Taking Skill Lessons by Other Persons</b>
        <ul>
          <li>(a) You may apply to us to take lessons provided by other users after identifying the lessons you wish to take through the Application.  If the lesson you applied for still has a vacancy, you will be registered as a participant of the lesson and be allowed to participate in the lesson.</li>
          <li>(b) You may cancel the registration for the lesson in a method separately specified by us.  We may cancel the lesson at our sole discretion without incurring any liability if necessary.</li>
          <li>(c) When you take lessons, you shall follow instructions set forth by us or the user providing the lesson. </li>
          <li>(d) We shall not take any responsibility for the provision or contents of lessons in any case, including cases where lessons prohibited under Section 4.(d) of Part II are offered by other users.</li>
          <li>(e) We shall not be responsible for any damage caused to users or third parties in relation to the implementation of, participation in or cancellation of lessons.</li>
        </ul>
      </li>
      <p class="text-bold">
        In addition, if there is a dispute between you and a third party (including other users) in relation to the implementation of, participation in, or cancellation of a lesson, you shall resolve such dispute at your own responsibility and expense, and shall not cause any damage to us.
      </p>
    </ol>
    <h4 class="text-center">
      PART III <br />
      TERMS AND CONDITIONS FOR SMART HEALTH CHECK SERVICE
    </h4>
    <ol>
      <li>
        <b>1. Scope of Service</b> In the SMART Health Check Service, the device for measuring height, weight, body fat percentage, BMI, muscle mass, blood pressure, etc., is provided in the Space allowing registered residents of Le Grand Jardin to take body measurements and receive advice from a nurse, if necessary.  Users may check the measured data and its changes over time on the Application.
      </li>
      <li>
        <b>2. Precautions for users of measuring devices</b>
        <ul>
          <li>
            (a) Neither we nor nurses who take the measurements using the device can guarantee the accuracy or otherwise of the measurement results.  We do not accept any liability to you or to any third party in connection with the results of your measurements.
          </li>
          <li>(b) We and the nurses cannot be held liable for any damage caused to you or any third party through no fault of ours or our nurses.</li>
          <li>(c) If you are under 20 years of age, you must be accompanied by or have the consent of a parent or guardian for use of the service.</li>
        </ul>
      </li>
      <li>
        <b>3. Advice from a nurse</b>
        <ul>
          <li>(a) Any advice given by a nurse in response to a measurement result is based on a threshold value set by us or our partner company, MEDRiNG Co., Ltd. ("<b>Medring</b>"), and is not medical advice.  Neither we nor Medring shall be liable for any loss or damage arising in connection with the advice even in the case where such advice is misleading, wrong, or otherwise inaccurate.</li>
          <li>(b) If you are undergoing medical treatment or guidance due to pregnancy, illness or for other reasons, you must inform the nurse in advance and may not be allowed to use this service.</li>
        </ul>
      </li>
      <li><b>4. Accumulation of measured data</b> You agree that the data measured pursuant to Section 1 of Part III will be stored in the Application or our server and used for the Services.  We will manage your personal data in the collected health measurement results in compliance with the Privacy Policy.</li>
    </ol>
    <h4 class="text-center">
      PART IV <br />
      TERMS AND CONDITIONS FOR SMART PLAYGROUND SERVICE
    </h4>
    <ol>
      <li><b>1. Scope of Service</b> In the Smart Playground Service, a play space (“<b>Smart Playground</b>”) is provided for children in the Space, and which is equipped with play equipment and cameras where family members can monitor the situation while the children are playing.</li>
      <li>
        <b>2. Scope of use</b>
        <ul>
          <li>(a) Users of the Smart Playground (i.e., children) shall be at least five years old and less than ten years old.  A user of the Smart Playground must be accompanied by the user's parent or guardian.  The user shall be a dependent of the guardian, or an equivalent thereof, and the guardian shall be at least 20 years old, follow the Terms and Conditions, ensure that the user complies with the Terms and Conditions, and supervise the user directly or indirectly by means of the camera equipped in the Smart Playground. </li>
          <li>(b) Users shall follow the instructions of staff while using the Smart Playground.  The user’s use of the Smart Playground may be restricted if the user fails to follow the instructions of staff despite several requests.  Staff will support the user's play only through suggestions on how to play in the Smart Playground; the guardian shall be fully responsible for ensuring the safety of the user at all times while the user is using the Smart Playground.</li>
          <li>(c) Food and drink cannot be brought into the Smart Playground.  Please eat or drink at specified locations in the surrounding area.</li>
        </ul>
      </li>
      <li><b>3. Precautions for use</b>  We shall not be responsible for any accidents or injuries to users, problems between users, or damages resulting from any accidents or injuries that may occur inside the Smart Playground.</li>
    </ol>
    <h4 class="text-center">
      PART V <br />
      TERMS AND CONDITIONS FOR HEALTHY CAFÉ SERVICE
    </h4>
    <ol>
      <li><b>1. Scope of Service</b> The café service is provided by Hotel du Park in the Space.  In addition to coffee and light snacks, this café offers a health-conscious menu, allowing you to experience a different healthy menu.  Only users who register necessary information via the Application may enter the café, and we shall not responsible for any food or drink served by the café.</li>
    </ol>
    <div class="note">
      <b>Enforcement Date of First Edition (Date of Last Update): <i>February 27th, 2022</i></b>
    </div>

    <h3 class="text-center text-uppercase my-4">
      Privacy Policy
    </h3>
    <p>
      SUMITOMO CORPORATION (referred to as “<b>us</b>”, “<b>we</b>”, or “<b>our</b>” as the context may require) sets forth this privacy policy (this “<b>Privacy Policy</b>”) to publish and notify you of how we collect and process your personal data in connection with providing the Services defined in the Terms and Conditions.  Please read this Privacy Policy before you use the Services.  The terms defined in the Terms and Conditions have the same meaning in this Privacy Policy.  By providing information to us, registering and creating your account through the Application, or using the Services, you hereby acknowledge that you have read this Privacy Policy, and you irrevocably and unconditionally consent to our collection, use, storage, management, and processing of your Personal Data (as defined below) in accordance with this Privacy Policy.  If you wish to request that we cease using your Personal Data, you hereby agree to contact us as instructed in Section 6 below.  If you do not agree to this Privacy Policy, please do not register for or use the Services.
    </p>
    <p>We are committed to the protection of the information we receive from you, and have implemented reasonable technical and organizational measures to achieve this goal.  However, you acknowledge that no data transmission over the Internet is completely secure, and that you transmit such information to us at your own risk.</p>
    <ol>
      <li>
        <b>1. How do we collect and process your personal data?</b>
        <p><b>(1) Collected Personal Data</b> </p>
        <p>
          We may collect the following types of data.  The data we collect may permit the identification of specific individuals and fall within the scope of personal data under applicable laws (“<b>Personal Data</b>”):
        </p>
        <ul>
          <li>(i) Information and account information electronically obtained at the time of user registration.</li>
          <li>(ii) Information (i.e., name, date of birth, address, telephone number, e-mail address, gender, age, facial image for authentication, health check results (including measurable data) and ID number that allows us to identify you) obtained when using the Application or Services, and the appearance, form, and movement of the user in the area.</li>
          <li>(iii) Information (consisting of names, addresses, telephone numbers, e-mail addresses, dates of birth, gender, age, answers to questionnaires, etc.) obtained from users participating in questionnaires, etc.  Your name, address, telephone number, e-mail address, date of birth, gender, and age will be obtained by various types of questionnaires, etc., and we will inform you in advance of the necessity of obtaining your information via a questionnaire, etc.</li>
          <li>(iv) Information obtained in connection with users’ use of the Application or Services, such as contents (photographs, articles, comments, etc.), service usage status of users, consumption behavior, and trends of user activity.</li>
          <li>(v) Image and other information that is collected by a facial recognition system which records video of users of the Services in the area where the Services are provided in order to ensure that the Services are provided in a safe manner.</li>
          <li>(vi) Other types of information and user inquiries to us.</li>
        </ul>
        <p><b>(2) Method of Collection </b></p>
        <p>We may collect Personal Data directly from you, either orally or in writing, when you register to use the Services, and later through your use of the Services.</p>
        <p><b>(3) Purpose and Scope of Use</b></p>
        <p>
          We may use your Personal Data for the following purposes (“<b>Purposes</b>”):
        </p>
        <ul>
          <li>(i) Provision of the Services and management of Services after your registration.</li>
          <li>(ii) Ensuring the safety of the Space where Services are provided and to manage the inventory of the items available for rent.</li>
          <li>(iii) Confirmation and communication of necessary matters in connection with the Application or Services, and in response to inquiries.</li>
          <li>(iv) To provide promotional information on products, services, and special offers by us and our partner companies, and for other marketing activities.</li>
          <li>(v) Validating and improving the algorithms that analyze certain risks to your health and  calculate the risk score based on the analysis, and that are used for developing insurance service products.</li>
          <li>(vi) Preparation of statistical data in a form that does not identify or specify individuals, and marketing analysis using such data, for reference in sales activities and development of new services.</li>
          <li>(vii) Using the information in combination with information separately held by us.</li>
          <li>(viii) Exercise of rights and other legal rights under the Terms and Conditions.</li>
        </ul>

        <p class="note">
          Notwithstanding the above, if we appropriately anonymize the Personal Data, we may use the anonymized data for any purpose.
        </p>
      </li>
      <li>
        <b>2. Who may access your personal data?</b>
        <p>Our employees may access the Personal Data.  Also, for the provision of the Services, we may need to disclose your Personal Data to other parties for one or more of the Purposes in the following cases:</p>
        <ul>
          <li>(i) Where we outsource the collection, storage, or processing of the Personal Data or the provision of the Services to, or share the Personal Data for the Purposes with, our partner companies such as health care companies or insurance companies, contractors such as IT vendors, or any third party (including but not limited to, Hakuhodo Inc., Medring, NEC Corporation, Square Communications JSC, Square Direct Advertising Company Ltd., MSIG Insurance (Vietnam) Company Limited, Mitsui Sumitomo Insurance Company, Limited and ReMark International B.V.);</li>
          <li>(ii) Where we transfer or disclose the Personal Data to our parent company or any other affiliate companies (including other companies in our corporate group; “<b>Group Companies</b>”);</li>
          <li>(iii) Where we are permitted to transfer or disclose the Personal Data to a third party without obtaining your consent under any law or regulation (regardless of whether domestic or foreign) applicable to us or any other Group Companies. </li>
          <li>(iv) Where we believe that you have acted in a way that is detrimental to other users or third parties, we may notify such third parties, the police, or other relevant authorities of your registration for the Services.</li>
          <li>
            (v) Where we need to disclose the Personal Data to a buyer or potential buyer in the case of a merger or acquisition, including preparations therefor.
          </li>
        </ul>
        <p>
          Notwithstanding the above, if we appropriately anonymize the Personal Data, we may disclose the anonymized data to any third party.  For instance, the following data may be disclosed after anonymization;
        </p>
        <ul>
          <li>
            (i) Attribute information of all users of the Service (information aggregating the age, sex, and place of residence of users).
          </li>
          <li>
            (ii) Content provided by you to the Company.
          </li>
          <li>(iii) Information that quantifies the responses of users to messages sent by the Company (number of users reached, impressions, clicks, etc.)</li>
          <li>
            (iv) Results of responses to questionnaires, etc.
          </li>
        </ul>
      </li>
      <li>
        <b>3. Where do we store and process your personal data?</b>
        <p>We may store and process the Personal Data in offices or data centers of the Group Companies or third party vendors, such as in Vietnam, Japan, Singapore or any other country.</p>
      </li>
      <li>
        <b>4. For how long do we store and process your personal data?</b>
        <p>
          To the fullest extent permitted by law, we may store and process the Personal Data until it is no longer necessary for any of the Purposes (we also may continue to store and process the Personal Data after the Agreement, based on the Terms and Conditions, has been terminated, as long as the Personal Data is still necessary for any of the Purposes).  Notwithstanding the above, we may store and process anonymized data without any limitations.
        </p>
      </li>
      <li>
        <b>5. What is the address of the controller who collects, manages, and processes your personal data?</b>
        <p>
          We, SUMITOMO CORPORATION, are responsible for collecting, managing, and processing the Personal Data, and our address is as follows:
        </p>
        <p>
          OTEMACHI PLACE EAST TOWER, 3-2 Otemachi 2-Chome, Chiyoda-ku, Tokyo 100-8601, Japan
        </p>
      </li>
      <li>
        <b>6. What should you do when you seek either to make a complaint regarding your personal data, or access, modify, and/or delete your personal data?
        </b>
        <p>If you wish to submit a complaint regarding your Personal Data or wish to access, modify, delete and/or request that we cease processing your Personal Data, please contact us as set forth in item (i) below.  Upon receiving your complaint or request, we will address or respond to it in the following way:</p>
        <ul>
          <li><span class="text-underline">(i) Step 1:</span>You may send your complaint or request to us in one of the following ways:</li>
          <li>Email: <a href="mailto:smartcityschn@gmail.com">smartcityschn@gmail.com</a></li>
          <li><span class="text-underline">(ii) Step 2:</span> Upon receiving your complaint or request, a customer service representative will verify your identity and, if necessary, request that you provide us with information or documents to clarify the contents of your complaint or request.  After completing this process, a customer service representative will transfer your complaint or request to the relevant person in charge.</li>
          <li><span class="text-underline">(iii) Step 3:</span> The relevant person in charge will contact you by phone or e-mail to address or respond to your complaint or request, or report to you that it has been addressed or completed.  If we are unable to address or respond to your complaint or request for a justifiable reason (e.g., when your request does not meet the requirements set forth in applicable laws), the relevant person in charge will explain such reason to you.</li>
        </ul>
      </li>
      <li>
        <b>7. Amendment of this Privacy Policy</b>
        <p>
          We may amend this Privacy Policy from time to time in accordance with Part I, Section 14 of the Terms and Conditions.
        </p>
      </li>
    </ol>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
#tac ol {
padding-left: 0;
}
ol > li > ul, ol, ul {
  list-style-type: none ;
  list-style-position: outside;
  &.none {
    list-style-type: lower-roman;
  }
  > ul {
    list-style-type: lower-roman;
  }
}
li {
  margin: 3vw 0;
}
.text-underline {
  text-decoration: underline;
}
</style>
