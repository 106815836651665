export default {
  // common
  LeGrandJardin: 'Dành riêng cho cư dân tại Le Grand Jardin',
  Home: 'Trang chủ',
  'Second Page': 'Trang thứ hai',
  Welcome: 'Chào mừng đến với Hanoi Smart City!',
  Login: 'Đăng nhập',
  RememberMe: 'Nhớ tài khoản',
  ForgotPassword: 'Quên mật khẩu',
  Other: 'Khác',
  Submit: 'Xác nhận',
  Back: 'Trở lại',
  Close: 'Đóng',
  Details: 'Chi tiết',
  Information: 'Thông tin',
  Family: 'Nhóm',
  Role: 'Vai trò',
  User: 'Người dùng',
  Nurse: 'Y tá',
  Staff: 'Nhân viên',
  Admin: 'Quản lý',
  Status: 'Trạng thái',
  Active: 'Kích hoạt',
  Inactive: 'Chưa kích hoạt',
  Activity: 'Hoạt động',
  Check: 'Kiểm tra',
  Edit: 'Chỉnh sửa',
  ChooseImage: 'Chọn hình ảnh',
  Accept: 'Đồng ý',
  Join: 'Tham gia',
  Leave: 'Rời đi',
  Deny: 'Từ chối',
  Reset: 'Đặt lại',
  at: 'vào lúc',
  AreYouSure: 'Bạn có chắc không?',
  Resend: 'Gửi lại',
  ResendAfterTime: 'Gửi lại sau {time}s',
  NoData: 'Không có dữ liệu',
  Next: 'Tiếp theo',
  Building: 'Tòa nhà',
  RoomNumber: 'Số phòng',
  Month: 'Tháng',
  Week: 'Tuần',
  Day: 'Ngày',
  Continue: 'Tiếp tục',
  Image: 'Hình ảnh',
  Approve: 'Phê duyệt',
  Reject: 'Từ chối',
  Delete: 'Xóa',
  DeleteExamination: 'Xóa thông tin',
  DeleteExaminationContent: 'Bạn có chắc chắn muốn xóa bài kiểm tra sức khỏe này?',

  LoginSubtitle: 'Xin vui lòng đăng nhập vào tài khoản của bạn',
  LoginSuggestion: 'Bạn đã có tài khoản?',
  BackToLogin: 'Trở lại trang đăng nhập',
  RegisterTitle: 'Đăng ký tài khoản',
  RegisterSubtitle: 'Vui lòng nhập chính xác số điện thoại để nhận mã OTP xác nhận',
  RegisterSuggestion: 'Bạn chưa có tài khoản?',
  AgreeToTerms: 'Tôi đồng ý',
  TermsOfService: 'Điều khoản dịch vụ',
  BackToRegister: 'Trở lại trang đăng ký',
  CreateAccount: 'Tạo tài khoản',
  ConfirmOTP: 'Xác thực OTP',
  ConfirmOTPSubtitle: 'Mã OTP đã được gửi tới {phoneNumber}. Vui lòng kiểm tra tin nhắn để lấy mã OTP',
  EnterOTP: 'Nhập OTP',
  ResendOTP: 'Gửi lại OTP',
  UpdatePassword: 'Cập nhật mật khẩu',
  UpdatePasswordSubtitle:
    'Cảm ơn bạn đã hoàn thành quá trình đăng ký tài khoản. Vui lòng cài đặt mật khẩu cho lần đăng nhập tiếp theo',
  ForgotPasswordSubtitle: 'Nhập số điện thoại đã đăng ký',
  UpdatePhoneNumber: 'Cập nhật số điện thoại',
  UpdatePhoneNumberSuccessMsg: 'Số điện thoại của bạn đã được cập nhật',
  UpdatePasswordSuccessMsg: 'Mật khẩu của bạn đã được cập nhật',
  BackToProfile: 'Trở lại trang cá nhân',
  CreateNewFamily: 'Tạo nhóm mới',
  LeaveFamily: 'Rời khỏi Nhóm',
  JoinFamily: 'Tham gia',
  NoFamilyMsg: 'Chưa có nhóm nào',
  FamilyInvitationTitle: 'Bạn đã chắc chắn chưa?',
  FamilyInvitationSubtitle:
    'Bạn sẽ tham gia group của {name} với vai trò là {role}. Nếu bạn đồng ý, hệ thống sẽ tự đồng từ chối các lời mời còn lại',
  FamilyInvitationsSubtitle: 'Bạn có {count} lời mời tham gia',
  FamilyInvitationsMsg: '{name} có số điện thoại là {phoneNumber} muốn mời bạn trở thành {role} trong group của họ',
  WaitingToAccept: 'Đang chờ chấp nhận',
  Invite: 'Gửi lời mời',
  WithPhone: 'Đã có tài khoản',
  WithoutPhone: 'Chưa có tài khoản',
  ChildHasAccount: 'Quan hệ',
  ChildInformation: 'Thông tin con của bạn',
  Timeline: 'Dòng thời gian',

  Password: 'Mật khẩu',
  CurrentPassword: 'Mật khẩu hiện tại',
  NewPassword: 'Mật khẩu mới',
  ConfirmPassword: 'Xác nhận mật khẩu',
  PhoneNumber: 'Số điện thoại',
  CurrentPhoneNumber: 'Số điện thoại hiện tại',
  NewPhoneNumber: 'Số điện thoại mới',
  Fullname: 'Họ và tên',
  Gender: 'Giới tính',
  Male: 'Nam',
  Female: 'Nữ',
  Age: 'Tuổi',
  FaceImage: 'Ảnh rõ mặt',
  FaceWithMaskImage: 'Ảnh đeo khẩu trang',
  FamilyName: 'Tên Nhóm',
  YourFamily: 'Nhóm của bạn',
  YearOfBirth: 'Năm sinh',
  ListMembers: 'Danh sách thành viên',
  AddMember: 'Thêm',
  TypeMember: 'Loại thành viên',

  UsersManagement: 'Quản lý người dùng',
  ListUsers: 'Danh sách người dùng',
  UserInformation: 'Thông tin người dùng',
  SearchByPhoneOrName: 'Nhập SĐT hoặc tên người dùng',
  UpdateInfo: 'Cập nhật thông tin',
  LockUser: 'Khoá tài khoản',
  UnlockUser: 'Mở khoá tài khoản',
  AreYouSureToLockUser: 'Bạn có chắc chắn muốn khoá tài khoản người dùng này không?',
  AreYouSureToUnlockUser: 'Bạn có chắc chắn muốn mở khoá tài khoản người dùng này không?',
  ResetPassword: 'Đặt lại mật khẩu',
  DeleteUser: 'Xoá tài khoản',
  AreYouSureToDeleteUser: 'Bạn có chắc chắn muốn xoá người dùng này không?',
  RemoveMember: 'Xoá thành viên',
  UserHasLockedMsg: 'Người dùng này đã bị khoá bởi {name}',

  // TownInfirmary
  'Town Infirmary': 'Thông tin sức khỏe',
  InfirmaryListUser: 'Danh sách bệnh nhân chờ khám',
  Search: 'Tìm kiếm',
  RefreshData: 'Làm mới dữ liệu',
  CheckIn: 'Check In',
  CheckUp: 'Check Up',
  CheckUpAt: 'Kiểm tra lúc',
  PhoneOrName: 'Nhập SDT hoặc tên ',
  ListExamTitle: 'Lịch sử khám',
  Examination: 'Chờ khám',
  'List Examination': 'Lịch sử',

  PhysicalExamination: 'Khám Bệnh',
  Name: 'Tên',
  Remove: 'Xóa',
  Wait: 'Chờ',
  Waiting: 'Đang chờ',
  Height: 'Chiều Cao',
  Weight: 'Cân Nặng',
  BMI: 'BMI',
  BloodPressure: 'Huyết Áp',
  BodyFat: 'Tỷ lệ mỡ',
  MuscleMass: 'Chỉ số cơ',
  DateMeasurement: 'Ngày khám',
  Save: 'Lưu',
  Cancel: 'Huỷ',
  SaveSuccess: 'Lưu thành công',
  RequiredFillData: 'Vui lòng điền đầy đủ thông tin',
  RemoveSuccess: 'Xoá thành công',

  Confirm: 'Xác nhận',
  ScanQR: 'Quét mã QR để đăng ký',
  CheckInConfirm: 'Xác nhận checkin số điện thoại',
  No: 'Không',
  Yes: 'Có',

  HealthInformation: 'Thông tin sức khỏe',
  You: 'Bạn',
  Child: 'Con',
  Parent: 'Bố/Mẹ',
  LastPhysicalExamination: 'Ngày kiểm tra sức khỏe gần nhất',
  From: 'Từ',
  To: 'Đến',
  HealthCheckOn: 'Kiểm tra sức khỏe ngày',
  PleaseUpload: 'Vui lòng tải ảnh khuôn mặt của bạn',

  // Cafe
  'Point Section': 'Point Section',
  View: 'View',
  ViewActivity: 'Hiển thị hoạt động',
  WelcomeStaffName: 'Chào mừng nhân viên',
  Orders: 'Orders',
  Coupon: 'Phiếu quà tặng',
  CouponScannedToday: 'Số lượng phiếu quà tặng đã sử dụng trong 1 ngày',
  ScanPoint: 'Quét mã QR phiếu quà tặng',
  ScanCreateItem: 'Quét mã QR của người dùng',
  ScanReturnItem: 'Quét mã QR vật phẩm',
  ScanBorrowItem: 'Quét mã QR vật phẩm bạn sẽ mượn',
  'Return item': 'Trả lại vật phẩm',
  'Are you sure you want to return this item': 'Bạn có chắc chắn muốn trả lại vật phẩm này',
  'Back list': 'Lấy lại vật phẩm',
  'Are you sure you want to back list this item': 'Bạn có chắc muốn lấy lại vật phẩm này',

  ScanCoupon: 'Quét mã QR phiếu quà tặng',
  CustomerName: 'Customer name',
  OrderID: 'Bill ID',
  CouponConfirm: 'Sử dụng phiếu quà tặng',
  Date: 'Ngày',
  ActivityOf: 'Hoạt động của',
  StaffScanPoint: 'Scan QR code for customer',
  StaffScanCoupon: 'đã được sử dụng bởi Khách hàng',
  StaffBillID: 'với số bill',
  StaffCouponCode: 'và mã thưởng',
  WithOrderNumber: 'with order number',
  History: 'Lịch sử',
  ExpirationDate: 'Ngày hết hạn',
  Point: 'Point',
  Change: 'Change',
  PointToCoupon: 'Point to coupon',
  PleaseGiveCode: 'Please give this code to the staff when ordering water, to accumulate points',
  PleaseGiveCodeCoupon: 'Vui lòng cho nhân viên kiểm tra mã QR này khi sử dụng',
  CouponList: 'Danh sách quà tặng',
  UsedDate: 'Ngày sử dụng',
  NotExprired: 'Chưa hết hạn',
  Expired: 'Đã hết hạn',
  Used: 'Đã sử dụng',
  NotUsed: 'Chưa sử dụng',

  // Sharebox
  'Share Box': 'Hộp chia sẻ',
  'See all available items': 'Danh sách vật phẩm',
  'Scan QR code': 'Mượn',
  Borrowing: 'Đang mượn',
  Lending: 'Cho mượn',
  Borrow: 'Mượn',
  BackList: 'Lấy lại',
  Return: 'Hoàn trả',
  YourItem: 'Vật phẩm của bạn',
  ListOfItemsYouBorrow: 'Danh sách vật phẩm bạn mượn',
  ListOfItemsCanBeBorrowed: 'Danh sách vật phẩm sẵn sàng cho mượn',
  ListOfItemsYouLend: 'Danh sách vật phẩm của bạn',
  ItemsNeedToBeReturnedToday: 'Vật phẩm cần được trả lại trong hôm nay',
  ReturnTime: 'Thời gian trả',
  ReturnTimeNow: 'Mượn trong',
  ItemHasBeenBorrowed: 'Vật phẩm đã cho mượn',
  ItemHasNotBeenBorrowed: 'Vật phẩm chưa cho mượn',
  CanBorrowTo: 'Có thể mượn tới',
  ViewQRCode: 'Hiện mã QR',
  Owner: 'Chủ sở hữu',
  SentTime: 'Thời gian gửi',
  Description: 'Mô tả',
  BorrowItem: 'Vật phẩm mượn',
  SuccessfullyRegistered: 'Bạn đã đăng kí mượn thành công vật phẩm này.',
  ShowThisQR: 'Hãy cho nhân viên kiểm trả mã QR để nhận vật phẩm.',
  ReturnBefore: 'Vui lòng trả lại vật phẩm trước',
  BeingBorrowedBy: 'Người mượn',
  Phone: 'SDT',
  ID: 'ID',
  Gallery: 'Hình ảnh minh hoạ',
  ReadyToBorrow: 'Vật phẩm sẵn sàng cho mượn',
  BeingBorrowed: 'Vật phẩm đã cho mượn',
  ItemsReturnToday: 'Vật phẩm sẽ được trả lại trong ngày',
  CreateNewItem: 'Tạo vật phẩm mới',
  ReturnItem: 'Trả vật phẩm',
  AvailableItems: 'Vật phẩm có sẵn',
  ListItemBorrowed: 'Danh sách vật phẩm đã được mượn',
  BorrowedItems: 'Vật phẩm đã mượn',
  ListItemsReadyBorrow: 'Danh sách vật phẩm có sẵn',
  Add: 'Thêm vào',
  BorrowTime: 'Thời gian mượn',
  NoContent: 'Thông tin đang được cập nhật',
  'Provide item from residents': 'Danh sách vật phẩm',
  'Borrow the item': 'Mượn vật phẩm',

  // Playground
  Playground: 'Sân chơi thông minh',
  NoMembersInPlaygroundMsg: 'Hiện tại không có thành viên nào thuộc Nhóm người dùng của bạn trong Sân chơi!',
  PlaygroundNoti: 'Cư dân {id_user} thuộc nhóm {family_name} đã vào khu Sân Chơi Thông Minh',
  PlaygroundNotiNoGroup: 'Cư dân {id_user} đã vào khu Sân Chơi Thông Minh',

  // activity_types
  login: 'Đăng nhập',
  change_password: 'Thay đổi mật khẩu',
  scan_add_order: 'Quét QRCode đơn hàng Cafe',
  leave_family: 'Rời khỏi nhóm',
  exchange_coupon: 'Quy đổi Coupon',
  use_coupon: 'Sử dụng quà tặng',
  change_phone: 'Thay đổi số điện thoại',
  update_user: 'Thay đổi thông tin',

  // SkillShare
  SkillShare: 'Chia sẻ kỹ năng',
  ListTopic: 'Danh mục',
  TopicName: 'Tên buổi chia sẻ',
  Speaker: 'Diễn giả',
  Time: 'Thời gian',
  Participants: 'Người tham gia',
  CheckinList: 'Danh sách Checkin',
  All: 'Tất cả',
  Joined: 'Đã tham gia',
  Approved: 'Đã phê duyệt',
  WaitApproval: 'Chờ phê duyệt',
  NotJoin: 'Chưa tham gia',
  Rejected: 'Bị từ chối',
  SkillShareActionsMsg: 'Bạn muốn mở một buổi chia sẻ hay đặt chỗ trước?',
  WaitStaffApproveMsg: 'Vui lòng chờ nhân viên phê duyệt',
  CreateSkillShare: 'Đăng kí chia sẻ kĩ năng',
  BookReservation: 'Đặt chỗ trước',
  CancelSharing: 'Huỷ buổi chia sẻ',
  CancelReservation: 'Hủy đặt chỗ',
  AreYouSureToCancelTopic: 'Bạn có muốn huỷ buổi chia sẻ không?',
  AreYouSureToRejectTopic: 'Bạn có muốn từ chối buổi chia sẻ không?',
  AreYouSureToApproveTopic: 'Bạn có muốn phê duyệt buổi chia sẻ không?',
  AreYouSureToLeaveTopic: 'Bạn có muốn huỷ tham gia buổi chia sẻ không?',
  AreYouSureToJoinTopic: 'Bạn có muốn tham gia buổi chia sẻ không?',
  RegisterSkillShare: 'Đăng ký chia sẻ',
  AgreeTermsSharing: 'Tôi đồng ý với những điều khoản quy định và sẽ chịu trách nhiệm với nội dung mình chia sẻ',
  Regulations: 'Quy định khi chia sẻ kỹ năng',
  RegulationsUpdate: 'Thông tin đang được cập nhật',
  ChooseTimeRange: 'Chọn khoảng thời gian',
  StartTime: 'Thời gian bắt đầu',
  EndTime: 'Thời gian kết thúc',
  ExpectedParticipants: 'Dự kiến số người tham gia',
  Topic: 'Chủ đề',
  ScanTopicToCheckin: 'Quét mã QR của buổi chia sẻ để checkin',
  Checkin: 'Checkin',
  SearchByQrCode: 'Tìm kiếm bằng mã QR',
  PrintQr: 'In mã QR',
  'Joining skill share': 'Tham gia chia sẻ',
  'Hold the skill share': 'Đăng kí chia sẻ',

  // Notification msgs
  general_001:
    'Lớp yoga miễn phí, voucher cafe 50.000 VND và quà hấp dẫn trong ngày 13.03.2022 từ lúc 14h. Đến ngay Smart Life Station!',
  account_001: 'Bạn nhận được một lời mời vào group',
  account_002: 'Bạn nhận được quà tặng',
  town_infirmary_001: 'Check-in success',
  cafe_002: 'Sử dụng quà tặng thành công',
  cafe_003: 'quà tặng sắp hết hạn',
  sharebox_001: 'Bạn nhận được quà tặng',
  sharebox_003: 'Vật phẩm của bạn đã được cho mượn',
  share_box_001: 'Bạn nhận được quà tặng',
  share_box_003: 'Vật phẩm của bạn đã được cho mượn',
  skillshare_001: 'Chủ đề của bạn đã được duyệt',
  skillshare_002: 'Bạn đã check in thành công',
  skillshare_003: 'Bạn nhận được quà tặng',
  skill_share_001: 'Chủ đề của bạn đã được duyệt',
  skill_share_002: 'Bạn đã check in thành công',
  skill_share_003: 'Bạn nhận được quà tặng',

  // Server
  'Not found': 'Không tìm thấy',
  'Permission denied': 'Không có quyền truy cập',
  'Coupon expired': 'Phiếu thưởng hết hạn',
  'Already check in': 'Sẵn sàng check in',
  'Already return to owner': 'Sẵn sàng trả',
  'Wrong status': 'Sai trạng thái',
  'Already borrowed': 'Sẵn sàng cho mượn',
  'Missing id borrow': 'Thiếu mã vật phẩm',
  'Missing return_at': 'Thiếu thời gian trả',
  'No data': 'Không có dữ liệu',
  'Out of seat': 'Đã hết chỗ',
  'You cannot delete yourself': 'Không thể tự xóa',
  'You cannot lock yourself': 'Không the tu lock',
  'You cannot assign yourself': 'Không thể tự gán',

  'Users Management': 'Quản lý người dùng',
  'List Coupon': 'Danh sách ưu đãi',
  'Coupon Management': 'Nhân viên - Danh sách ưu đãi',
  'Share Box Management': 'Nhân viên - Hộp chia sẻ',
  'Skill Share Management': 'Nhân viên - Chia sẻ kỹ năng',
  'See security camera': 'Xem camera giám sát',
  'Play ground': 'Sân chơi',

  Hi: 'Chào',
  ViewCoupon: 'Danh sách quà tặng',
  Print: 'In',
  Speakers: 'Diễn giả',
  List: 'Danh sách',
  Calendar: 'Lịch',
  Logout: 'Đăng xuất',
  Profile: 'Thông tin người dùng',
}
