import store from '@/store'

export const getRole = roleId => {
  const { roles } = store.state.auth

  return roles.find(r => r.id_role === roleId)?.role_name
}

export const getGender = genderId => {
  const genders = ['Other', 'Male', 'Female']

  return genders[genderId]
}

const accountHelpers = {
  getRole,
  getGender,
}

export default accountHelpers
